import {metrika} from '@eda-restapp/logger'
import {useNavigate} from 'react-router'

import {getPromotionMainPath} from '../../../../../../../../constants'

import {BonusesGuideSection} from './BonusesGuideSection'

export const BonusesGuideSectionContainer = () => {
  const navigate = useNavigate()

  const handleRedirectToPromotion = () => {
    metrika({target: 'bonuses_promo_drawer_click_start_promotion'})

    navigate(getPromotionMainPath())
  }

  return <BonusesGuideSection onRedirectToPromotion={handleRedirectToPromotion} />
}
