import type {UseMutationResult, UseMutationOptions} from '@tanstack/react-query'
import {useMutation} from '@tanstack/react-query'

import {FALLBACK_SNACKBAR_ERROR_MESSAGE} from '@restapp/core-legacy/constants/messages/errors'
import type {IApiError, VendorApi as Api} from '@restapp/shared-api'

import type {Response, ApiQueryKey, Method, RequestConfigNarrow, TVariables} from '../../types'
import {useApiDeps} from '../useApiDeps'

type BaseMutationOptions<TResponse, TVariables> = Omit<
  UseMutationOptions<TResponse, IApiError, TVariables>,
  'mutationFn'
>

export interface MutationOptions<TResponse, TVariables> extends BaseMutationOptions<TResponse, TVariables> {
  affectedQueryKeys?: ApiQueryKey[] // TODO: ApiQueryKey<TUrl, TMethod>
  fallbackErrorMessage?: string
}

export default function useApiMutation<TUrl extends keyof Api, TMethod extends Method<TUrl>>(
  config: Expand<RequestConfigNarrow<TUrl, TMethod>>,
  {
    affectedQueryKeys = [],
    fallbackErrorMessage = FALLBACK_SNACKBAR_ERROR_MESSAGE.default,
    ...options
  }: MutationOptions<Response<TUrl, TMethod>, TVariables<TUrl, TMethod>> = {}
): UseMutationResult<Response<TUrl, TMethod>, IApiError, TVariables<TUrl, TMethod>> {
  const {onError, onSuccess} = options

  const {queryClient, request, snackbar} = useApiDeps()

  return useMutation(
    ({body, params, headers}) =>
      request({
        ...config,
        headers: headers ?? config.headers,
        params: params ?? config.params,
        data: body ?? config.body
      }) as Promise<Response<TUrl, TMethod>>,
    {
      ...options,
      onError: (error, ...opts) => {
        if (onError) {
          return onError(error, ...opts)
        } else {
          snackbar.enqueue({type: 'error', message: error?.responseData?.message || fallbackErrorMessage, error})
        }
      },
      onSuccess: (data, variables, context) => {
        const invalidationTasks = affectedQueryKeys.map((queryKey) => queryClient.invalidateQueries(queryKey))

        return Promise.all(invalidationTasks).then(() => onSuccess?.(data, variables, context))
      }
    }
  )
}
