import {useHeavyPlaces} from './useHeavyPlaces'
import {usePlaces} from './usePlaces'

type HeavyPlaceOptions = Parameters<typeof useHeavyPlaces>[0]

export const useHeavyPlace = (options: HeavyPlaceOptions = {}) => {
  const {selectedPlaceId, isLoading: isLoadingPlaces, places} = usePlaces(options as Parameters<typeof usePlaces>[0])
  const {getPlaceById, isFetching: isHeavyPlacesFetching} = useHeavyPlaces({enabled: true, limit: 999, ...options})

  return {
    isLoading: isLoadingPlaces || isHeavyPlacesFetching,
    place: getPlaceById(selectedPlaceId),
    places,
    selectedPlaceId
  }
}
