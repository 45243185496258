import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import type {FinanceReport} from '../types'

const initialState: FinanceSliceState = {
  infoDrawer: {},
  createReportDrawer: {
    open: false
  }
}

export const financeSlice = createSlice({
  name: 'finance',
  initialState: () => initialState,
  reducers: {
    openInfoDrawer: (state, action: PayloadAction<FinanceSliceState['infoDrawer']['reportId']>) => {
      state.infoDrawer.reportId = action.payload
    },
    closeInfoDrawer: (state) => {
      delete state.infoDrawer.reportId
    },
    openCreateReportDrawer: (state) => {
      state.createReportDrawer.open = true
    },
    openCreateReportDrawerWithParams: (state, action: PayloadAction<FinanceReport>) => {
      state.createReportDrawer.open = true
      state.createReportDrawer.params = action.payload
    },
    closeCreateReportDrawer: (state) => {
      state.createReportDrawer.open = false
      delete state.createReportDrawer.params
    },
    resetCreateReportDrawerParams: (state) => {
      delete state.createReportDrawer.params
    }
  },
  selectors: {
    selectInfoDrawerReportId: (state) => state.infoDrawer.reportId,
    selectCreateReportDrawerOpen: (state) => state.createReportDrawer.open,
    selectCreateReportDrawerParams: (state) => state.createReportDrawer.params
  }
})

type FinanceSliceState = {
  infoDrawer: {
    reportId?: string
  }
  createReportDrawer: {
    open: boolean
    params?: FinanceReport
  }
}
