import {useI18n} from '@eda-restapp/i18n'
import {Box, Spinner, Typography, useIsMobileStrict} from '@eda-restapp/ui'
import type {FC} from 'react'

import type {BonusesCampaign} from '@restapp/shared-api/types/promotion'

import {SwitchTabs} from '../../../../../../../../components/SwitchTabs'
import {PlaceCardContainer} from '../../../../../PlaceCard/PlaceCard.container'
import type {BonusesTab} from '../../BonusesListTab.container'

type BonusesListProps = {
  onToggleBonusesTab: () => void
  tab: BonusesTab
  showTabs: boolean
  bonusesCampaigns?: BonusesCampaign[]
  onNavigateToPromotionDrawer: (id: string) => void
  onNavigateToCampaignDrawer: (id: string) => void
  isBonusesFetched: boolean
}

export const BonusesList: FC<BonusesListProps> = ({
  tab,
  showTabs,
  onToggleBonusesTab,
  bonusesCampaigns,
  onNavigateToPromotionDrawer,
  onNavigateToCampaignDrawer,
  isBonusesFetched
}) => {
  const {t} = useI18n()
  const isMobile = useIsMobileStrict()

  if (!isBonusesFetched) {
    return (
      <Box flexGrow={1} alignItems='center' justifyContent='center'>
        <Spinner />
      </Box>
    )
  }

  return (
    <Box p={isMobile ? 'm' : 'l'} flexDirection='column' gap={isMobile ? 'm' : 'l'}>
      {showTabs ? (
        <SwitchTabs
          tabs={[
            {
              label: t('promotion.bonuses-list.hurry-up-to-apply', 'Успейте применить'),
              value: 'new'
            },
            {
              label: t('promotion.bonuses-list.applyed', 'Уже применили'),
              value: 'accepted'
            }
          ]}
          value={tab}
          onChange={() => onToggleBonusesTab()}
        />
      ) : (
        <Typography data-testid='one-tab-bonuses-header' variant='body1' thickness='medium' lineHeight='tight' mt='s'>
          {tab === 'new'
            ? t('promotion.bonuses-list.hurry-up-to-apply', 'Успейте применить')
            : t('promotion.bonuses-list.applyed', 'Уже применили')}
        </Typography>
      )}

      {!!bonusesCampaigns && (
        <Box flexDirection='column' gap='m'>
          {bonusesCampaigns.map((campaign) => (
            <PlaceCardContainer
              key={campaign.balance_id}
              burnSoon={campaign.burn_soon}
              currentBalance={campaign.balance}
              placeId={Number(campaign.place_id)}
              totalBalance={campaign.total}
              appliedAt={campaign.applied_at ? new Date(campaign.applied_at) : undefined}
              applyBefore={campaign.apply_before ? new Date(campaign.apply_before) : undefined}
              conditionsLink={campaign.conditions_link}
              onClick={() =>
                campaign.applied_at
                  ? onNavigateToCampaignDrawer(campaign.place_id)
                  : onNavigateToPromotionDrawer(campaign.place_id)
              }
              upToOrders={campaign.order_cnt}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}
