import {makeStyles} from 'tss-react/mui'

export const useNewChatButtonStyle = makeStyles({name: 'NewChatButton'})((theme) => {
  return {
    plusIcon: {
      minWidth: 24
    },
    popoverRoot: {
      zIndex: '100!important' as unknown as number
    },
    popoverPaper: {
      backgroundColor: '#fff',
      borderRadius: 12,
      padding: '16px 16px 0',
      boxShadow: '0 8px 20px rgba(117, 115, 111, 0.2)',
      maxWidth: '96%',
      maxHeight: '70%',

      [theme.breakpoints.up('md')]: {
        maxWidth: 500,
        maxHeight: 500
      }
    },
    popoverTitle: {
      marginBottom: 16,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '19px'
    },
    restaurant: {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: 'auto 1fr auto',
      height: 58
    },
    restaurantInfo: {
      marginRight: 8
    },
    restaurantName: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '18px'
    },
    deliveryIcon: {
      width: 24,
      height: 24,
      marginRight: 8
    }
  }
})
