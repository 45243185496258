import type {FC} from 'react'
import {useNavigate} from 'react-router'

import type {BonusesCampaign} from '@restapp/shared-api/types/promotion'

import {usePromoDrawerContext} from '../../../../hooks/usePromoDrawerContext'
import type {BonusesTab} from '../../BonusesListTab.container'

import {BonusesList} from './BonusesList'

type BonusesListContainer = {
  onToggleBonusesTab: () => void
  tab: BonusesTab
  newBonusesCampaigns?: BonusesCampaign[]
  acceptedBonusesCampaigns?: BonusesCampaign[]
  isBonusesFetched: boolean
  onToggleDrawerTab: () => void
}

export const BonusesListContainer: FC<BonusesListContainer> = ({
  onToggleBonusesTab,
  onToggleDrawerTab,
  tab,
  newBonusesCampaigns,
  acceptedBonusesCampaigns,
  isBonusesFetched
}) => {
  const navigate = useNavigate()

  const hasNewBonusesCampaigns = newBonusesCampaigns && newBonusesCampaigns.length > 0
  const hasAcceptedBonusesCampaigns = acceptedBonusesCampaigns && acceptedBonusesCampaigns.length > 0

  const promoDrawerContext = usePromoDrawerContext()

  const showTabs = hasNewBonusesCampaigns && hasAcceptedBonusesCampaigns

  const bonusesCampaigns = hasNewBonusesCampaigns && tab === 'new' ? newBonusesCampaigns : acceptedBonusesCampaigns

  const handleNavigateToPromotionDrawer = (placeId: string) => {
    navigate(`/promotion/places?startByBonusesIds=${placeId}`)
    onToggleDrawerTab()
  }

  const handleNavigateToCampaignDrawer = (placeId: string) => {
    const campaign = promoDrawerContext.campaigns?.find((campaign) => campaign.place_id === Number(placeId))

    if (campaign?.campaign_uuid) {
      navigate(`promotion/places?campaignId=${campaign?.campaign_uuid}`)
      onToggleDrawerTab()
    }
  }

  return (
    <BonusesList
      onToggleBonusesTab={onToggleBonusesTab}
      showTabs={!!showTabs}
      tab={tab}
      bonusesCampaigns={bonusesCampaigns}
      onNavigateToPromotionDrawer={handleNavigateToPromotionDrawer}
      onNavigateToCampaignDrawer={handleNavigateToCampaignDrawer}
      isBonusesFetched={isBonusesFetched}
    />
  )
}
