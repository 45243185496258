import {useExp3} from '@eda-restapp/configs'
import {metrika} from '@eda-restapp/logger'
import {useMatch} from 'react-router-dom'

import {useModal} from '@restapp/shared-modals'

import {PROMOTION_BONUSES_DRAWER_NAME} from '../../constants'
import {useBonusesBalance} from '../../hooks/useBonusesBalance'

import {BonusesBalance} from './BonusesBalance'

export const BonusesBalanceContainer = () => {
  const promotionConfig = useExp3('restapp_promotion')
  const bonusesBalance = useBonusesBalance()
  const {open} = useModal({modalName: PROMOTION_BONUSES_DRAWER_NAME})

  const matchHome = useMatch('/home')
  const matchPromotion = useMatch('/promotion')

  const handleOpenBonusesDrawer = () => {
    if (matchHome || matchPromotion) {
      metrika({target: 'bonuses_click_open_promo_drawer', params: {place: matchHome ? 'home' : 'promotion'}})
    }
    open()
  }

  if (!bonusesBalance.total || !promotionConfig.cpaBonuses.isStartByBonusesAvailable) {
    return null
  }

  return (
    <>
      <BonusesBalance
        total={bonusesBalance.total}
        balanceToBurn={bonusesBalance.balanceToBurn}
        onOpenBonusesDrawer={handleOpenBonusesDrawer}
      />
    </>
  )
}
