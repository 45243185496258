import type {Configs, ConfigsSource} from '@eda-restapp/debug-menu'
import {useExp3, getConfigsStore} from '@eda-restapp/configs'
import {getRuntimeEnvironment} from '@eda-restapp/utils'
import React, {useEffect, useRef} from 'react'
import {ErrorBoundary, Suspense} from '@restapp/shared-boundary'
import {merge} from 'lodash-es'
import {useQueryClient} from '@tanstack/react-query'
import {useIsEnabledInCurrentNetwork} from '@restapp/shared'
import {sessionStore} from '@eda-restapp/logger'
import {notificationsChannel} from '@eda-restapp/microfrontend'
import type {AppNotifications} from '@eda-restapp/microfrontend'
import {useDispatch} from 'react-redux'
import {soundsSlice} from '@restapp/bootstrap/sounds/slice/sounds.slice'

const DebugMenu = React.lazy(() => import(/* webpackChunkName: "DebugMenu" */ '@eda-restapp/debug-menu/DebugMenu'))

const store = getConfigsStore()

const DebugMenuContainer = () => {
  const configs = useExp3()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const unsubscribeBeforeSet = useRef<() => boolean>()
  const unsubscribeFetchStart = useRef<() => boolean>()

  const isTesting = getRuntimeEnvironment() === 'testing'
  const isDev = getRuntimeEnvironment() === 'development'
  const isEnabledInCurrentNetwork = useIsEnabledInCurrentNetwork(configs.restapp_common.showConfigEditor)
  const isDebugMenuEnabled = isDev || isTesting || isEnabledInCurrentNetwork

  useEffect(
    () => () => {
      void unsubscribeBeforeSet.current?.()
      void unsubscribeFetchStart.current?.()
    },
    []
  )

  const handleInvalidateConfigs = () => {
    store.clearCache()
    queryClient.invalidateQueries(['configs'])
  }

  const handleSourceChange = (source: ConfigsSource) => {
    if (!isTesting && !isDev) {
      return
    }

    unsubscribeFetchStart.current?.()

    if (source === 'prod') {
      unsubscribeFetchStart.current = store.fetcher.events.subscribe({
        name: 'fetchStart',
        callback: (payload) => {
          merge(payload.request, {url: '/4.0/prod/restapp-front/eats/v1/ab/configs'})
        }
      })
    }

    queryClient.cancelQueries(['configs'])
    handleInvalidateConfigs()
  }

  const handleChange = (configs: Configs | null) => {
    unsubscribeBeforeSet.current?.()

    if (configs) {
      unsubscribeBeforeSet.current = store.events.subscribe({
        name: '__doNotUseThis_beforeSet',
        callback: (payload) => {
          merge(payload.configs, configs)
        }
      })
    }

    handleInvalidateConfigs()
  }

  if (!isDebugMenuEnabled) {
    return null
  }

  return (
    <ErrorBoundary slug='config-editor-boundary'>
      <Suspense slug='config-editor-suspense' fallback={''}>
        <DebugMenu
          requestId={sessionStore.data.requestId}
          value={configs}
          sourceSelectAvailable={isTesting || isDev}
          onNotificationSend={(notification) =>
            notificationsChannel.broadcast(notification as unknown as AppNotifications)
          }
          onSoundsVolumeChange={(volume) => dispatch(soundsSlice.actions.setSoundsVolume(volume))}
          onChange={handleChange}
          onSourceChange={handleSourceChange}
        />
      </Suspense>
    </ErrorBoundary>
  )
}

export default DebugMenuContainer
