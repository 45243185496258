import {useExp3} from '@eda-restapp/configs'
import React from 'react'

import {useHeavyPlace} from '@restapp/core-places'
import {RESTAURANT_DISABLE_REASONS} from '@restapp/shared'
import {Suspense} from '@restapp/shared-boundary'
import {Drawer} from '@restapp/shared-ui/Drawer'

import {usePlusDrawer} from '../../hooks/usePlusDrawer'

const PlusDrawer = React.lazy(() =>
  import(/* webpackChunkName: "@restapp/shared-places/components/PlusDrawer" */ '../../components/PlusDrawer').then(
    (module) => {
      return {default: module.PlusDrawer}
    }
  )
)

export const PlusContainer: React.FC = () => {
  const placeConfig = useExp3('restapp_place')
  const plusConfig = useExp3('restapp_plus')

  const {isOpen, close} = usePlusDrawer()
  const {place, isLoading} = useHeavyPlace()

  const isSelfRegPending = () =>
    place?.disable_details
      ? place.disable_details.reason === RESTAURANT_DISABLE_REASONS.SELF_REGISTRATION_PENDING
      : false

  const isSelfRegComplete = () =>
    place?.disable_details ? place.disable_details.reason === RESTAURANT_DISABLE_REASONS.SELF_REGISTRATION_DONE : false

  const isSelfReg = () => !!placeConfig.new_design_self_registration && (isSelfRegPending() || isSelfRegComplete())

  if (!plusConfig.enabled || isSelfReg() || isLoading) {
    return null
  }

  return (
    <Drawer open={isOpen} onClose={close} disablePortal>
      <Suspense>{isOpen && <PlusDrawer />}</Suspense>
    </Drawer>
  )
}
