import {useApiQuery} from '@restapp/core-api'
import {usePlaces} from '@restapp/core-places'
import type {BonusesCampaign, BonusesCampaignStatus} from '@restapp/shared-api/types/promotion'

type UseBonusesCampaignsProps = {
  campaignsStatus: BonusesCampaignStatus
  limit?: number
  balanceId?: string
  burnedDownAt?: string
  keepPreviousData?: boolean
  refetchInterval?: number
  onSuccess?: (data: {campaigns: BonusesCampaign[]}) => void
  placesIds?: number[]
}

export const useBonusesCampaigns = ({
  campaignsStatus,
  limit = 1,
  balanceId,
  burnedDownAt,
  keepPreviousData,
  refetchInterval,
  placesIds: externalPlacesIds,
  onSuccess
}: UseBonusesCampaignsProps) => {
  const {placesIds} = usePlaces()

  const bonusesCampaignsQuery = useApiQuery({
    url: '/4.0/restapp-front/place-bonus-points/v1/campaigns/list',
    method: 'POST',
    body: {
      place_ids: externalPlacesIds ? externalPlacesIds.map(String) : placesIds.map(String),
      feature: 'cpa_ad_conversion',
      status: campaignsStatus,
      meta: {
        limit,
        balance_id: balanceId,
        burned_down_at: burnedDownAt
      }
    },
    keepPreviousData,
    refetchInterval,
    onSuccess
  })

  return {
    campaigns: bonusesCampaignsQuery.data?.campaigns,
    isFetched: bonusesCampaignsQuery.isFetched,
    isLoading: bonusesCampaignsQuery.isFetching
  }
}
