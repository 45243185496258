import {Drawer} from '@eda-restapp/ui'
import type {FC} from 'react'

import {AvailableBonusesSectionContainer} from './components/AvailableBonusesSection/AvailableBonusesSection.container'
import {BonusesGuideSectionContainer} from './components/BonusesGuideSection/BonusesGuideSection.container'
import {HeadSectionContainer} from './components/HeadSection/HeadSection.container'

type PromoTabProps = {
  onClose: () => void
  onToggleDrawerTab: () => void
}

export const PromoTab: FC<PromoTabProps> = ({onClose, onToggleDrawerTab}) => {
  return (
    <Drawer.Container floatClose onClose={onClose}>
      <Drawer.Content>
        <HeadSectionContainer />
        <AvailableBonusesSectionContainer onToggleDrawerTab={onToggleDrawerTab} />
        <BonusesGuideSectionContainer />
      </Drawer.Content>
    </Drawer.Container>
  )
}
