import {metrika} from '@eda-restapp/logger'
import {useMemo, type FC} from 'react'
import {useNavigate} from 'react-router'

import {useBonusesBalance} from '../../../../../../hooks/useBonusesBalance'
import {useBonusesCampaigns} from '../../../../../../hooks/useBonusesCampaigns'
import {usePromoDrawerContext} from '../../../../hooks/usePromoDrawerContext'

import {AvailableBonusesSection} from './AvailableBonusesSection'
import {getBonusesCampaigns} from './helpers/getBonusesCampaigns'

type AvailableBonusesSectionContainerProps = {
  onToggleDrawerTab: () => void
}

export const AvailableBonusesSectionContainer: FC<AvailableBonusesSectionContainerProps> = ({onToggleDrawerTab}) => {
  const navigate = useNavigate()

  const promoDrawerContext = usePromoDrawerContext()

  const promotionCampaigns = promoDrawerContext.campaigns

  const notActivatedCampaigns = useMemo(() => {
    if (!promotionCampaigns) {
      return undefined
    }

    return promotionCampaigns
      .filter((campaign) => !campaign.activated_by_user && campaign.has_access_vendor)
      .map((campaign) => campaign.place_id)
  }, [promotionCampaigns])

  const newBonusesCampaigns = useBonusesCampaigns({
    campaignsStatus: 'new',
    limit: 4,
    placesIds: notActivatedCampaigns
  })
  const acceptedBonusesCampaigns = useBonusesCampaigns({campaignsStatus: 'accepted', limit: 4})
  const bonusesBalance = useBonusesBalance()

  const bonusesCampaigns = getBonusesCampaigns({
    newBonusesCampaigns: newBonusesCampaigns.campaigns,
    acceptedBonusesCampaigns: acceptedBonusesCampaigns.campaigns
  })

  const handleNavigateToPromotionDrawer = (placeId: string) => {
    navigate(`/promotion/places?startByBonusesIds=${placeId}`)
  }

  const handleNavigateToCampaignDrawer = (placeId: string) => {
    const campaign = promoDrawerContext.campaigns?.find((campaign) => campaign.place_id === Number(placeId))

    if (campaign?.campaign_uuid) {
      navigate(`promotion/places?campaignId=${campaign?.campaign_uuid}`)
    }
  }

  const handleToggleDrawerTab = () => {
    metrika({target: 'bonuses_promo_drawer_click_all_my_bonuses'})

    onToggleDrawerTab()
  }

  return (
    <AvailableBonusesSection
      bonusesBalance={bonusesBalance.total}
      bonusesCampaigns={bonusesCampaigns.campaigns}
      manyBonusesCampaigns={bonusesCampaigns.manyCampaigns}
      onNavigateToPromotionDrawer={handleNavigateToPromotionDrawer}
      onNavigateToCampaignDrawer={handleNavigateToCampaignDrawer}
      onToggleDrawerTab={handleToggleDrawerTab}
      isBonusesFethed={newBonusesCampaigns.isFetched && acceptedBonusesCampaigns.isFetched}
    />
  )
}
