import type {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const UserAvatarStyles = (theme: Theme) =>
  ({
    root: {
      alignItems: 'center',
      display: 'flex',
      whiteSpace: 'nowrap'
    },

    avatarContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between'
      }
    },

    avatarAsColumn: {
      flexDirection: 'row',
      justifyContent: 'center'
    },

    avatar: {
      position: 'relative',
      marginRight: 12
    },

    avatarIcon: {
      userDrag: 'none'
    },

    avatarIconDisabled: {
      opacity: 0.5
    },

    error: {
      backgroundSize: 'contain',
      position: 'absolute',
      right: -5,
      top: -5
    },

    errorIcon: {
      verticalAlign: 'middle'
    },

    notifyContainer: {
      position: 'relative',
      width: 10,
      height: 40
    },

    notify: {
      position: 'absolute',
      right: -6,
      bottom: -6,
      fontSize: 20
    },

    notifyAfterName: {
      bottom: 'unset',
      right: 'unset',
      left: -7,
      top: 0
    },

    name: {
      color: '#1F2021',
      fontWeight: 500,
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      '&:first-letter': {
        color: '#F5523A'
      }
    },

    subtitle: {
      color: '#999C9E',
      fontWeight: 400,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },

    disabled: {
      color: '#999C9E',

      '&:first-letter': {
        color: '#999C9E'
      }
    },

    alertTooltip: {
      zIndex: 130000
    },

    fullAlertMessage: {
      color: '#F5523A',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '14px',
      whiteSpace: 'break-spaces',
      paddingTop: 6,
      textAlign: 'center'
    }
  }) as const

const useStyles = makeStyles({name: 'UserAvatar'})(UserAvatarStyles)

export default useStyles
