import {Box, Spinner} from '@eda-restapp/ui'
import type {InfiniteQueryObserverBaseResult} from '@tanstack/react-query'
import React, {type FC} from 'react'

import {useInfiniteLoader} from '@restapp/shared'
import type {FullPlaceType, IApiError} from '@restapp/shared-api'
import type {SearchQueryResponse} from '@restapp/shared-api/types/search'

import {PlaceStatusListContentItem} from '../PlaceStatusListContentItem/PlaceStatusListContentItem'

type PlaceStatusListContentProps = {
  canLoadMorePlaces: boolean
  isFetchingNextPlaceQueryPage: boolean
  places: FullPlaceType[]
  heavyPlacesQueryLoadMore: InfiniteQueryObserverBaseResult<SearchQueryResponse, IApiError>['fetchNextPage']
  onRefetch: () => Promise<void>
  onBusyModeButtonClick: (placeId: number) => void
}

export const PlaceStatusListContent: FC<PlaceStatusListContentProps> = ({
  canLoadMorePlaces,
  isFetchingNextPlaceQueryPage,
  places,
  heavyPlacesQueryLoadMore,
  onRefetch,
  onBusyModeButtonClick
}) => {
  const {loaderRef} = useInfiniteLoader<HTMLDivElement>({
    canLoadMore: canLoadMorePlaces,
    loadMore: heavyPlacesQueryLoadMore
  })

  return (
    <Box flexDirection='column'>
      <ul>
        {places.map((place) => (
          <PlaceStatusListContentItem
            key={place.id}
            place={place}
            onBusyModeButtonClick={onBusyModeButtonClick}
            onRefetch={onRefetch}
          />
        ))}
      </ul>

      <Box justifyContent='center' pt='m' ref={loaderRef}>
        {isFetchingNextPlaceQueryPage && <Spinner />}
      </Box>
    </Box>
  )
}
