import {useI18n} from '@eda-restapp/i18n'
import {Box, Typography, Button, BonusIcon} from '@eda-restapp/ui'
import cn from 'classnames'
import type {FC} from 'react'

import {formatNumber} from '@restapp/shared/utils'

import ClockImage from './assets/clock.png'
import styles from './BonusesBalance.module.css'

export type BonusesBalanceProps = {
  total: string
  balanceToBurn?: string
  onOpenBonusesDrawer: () => void
}

export const BonusesBalance: FC<BonusesBalanceProps> = ({total, balanceToBurn, onOpenBonusesDrawer}) => {
  const {t, lang} = useI18n()

  return (
    <Box className={styles.root}>
      <Button.Base
        data-testid='balance-bonuses'
        variant='promotion'
        size='m'
        fullWidth
        baseStyles={{
          background: balanceToBurn
            ? 'linear-gradient(95.04deg, #fcb500 6.26%, #ec8600 29.95%, #ec7600 82.8%, #cb5100 96.93%)'
            : undefined,
          borderRadius: '8px'
        }}
        baseClasses={styles.buttonBase}
        onClick={onOpenBonusesDrawer}
      >
        <Box gap='s' alignItems='center' className={cn(balanceToBurn ? styles.balanceToBurnCenter : styles.center)}>
          {!!balanceToBurn && <img className={styles.clockIcon} src={ClockImage} alt='clock' />}

          <Box flexDirection='column' alignItems='center' justifyContent='center'>
            <Box gap='s' alignItems='center'>
              <Typography variant='body2' lineHeight='normal' thickness='medium' color='invert'>
                {formatNumber(Number(total), lang, {useGrouping: true})}
              </Typography>
            </Box>

            {balanceToBurn ? (
              <Typography variant='caption2' lineHeight='tight' thickness='medium' color='invert'>
                {t('promotion.bonuses-balance.bonuses-to-burn', '{bonuses} скоро истекут', {
                  bonuses: formatNumber(Number(balanceToBurn), lang, {useGrouping: true})
                })}
              </Typography>
            ) : (
              <Typography variant='caption2' lineHeight='tight' thickness='medium' color='invert'>
                {t('promotion.bonuses-balance.bonuses-total', 'бонусов', {
                  one: 'бонус',
                  some: 'бонуса',
                  many: 'бонусов',
                  count: total
                })}
              </Typography>
            )}

            <BonusIcon className={cn(styles.bonusesIcon, !balanceToBurn && styles.greenBonuseIcon)} color='white' />
          </Box>
        </Box>
      </Button.Base>
    </Box>
  )
}
