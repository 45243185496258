import {useDeliveryName} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import moment from 'moment'
import type {FC} from 'react'

import {usePlaces} from '@restapp/core-places'

import {PlaceCard} from './PlaceCard'

type PlaceCardContainerProps = {
  placeId: number
  burnSoon: boolean
  totalBalance: string
  currentBalance: string
  conditionsLink: string
  upToOrders: number
  onClick: () => void
  appliedAt?: Date
  applyBefore?: Date
  className?: string
}
export const PlaceCardContainer: FC<PlaceCardContainerProps> = ({
  placeId,
  appliedAt,
  applyBefore,
  currentBalance,
  totalBalance,
  upToOrders,
  burnSoon,
  onClick,
  conditionsLink,
  className
}) => {
  const {getPlaceById} = usePlaces()
  const shortPlace = getPlaceById(placeId)
  const getDeliveryName = useDeliveryName()

  if (!shortPlace) {
    return null
  }

  const handleClick = () => {
    if (!appliedAt) {
      metrika({target: 'bonuses_promo_drawer_card_click_use_bonuses', params: {place_id: shortPlace.id}})
    }
    onClick()
  }

  const deliverySlug = getDeliveryName({
    placeServices: shortPlace.services,
    placeType: shortPlace.type
  })

  return (
    <PlaceCard
      className={className}
      placeName={shortPlace.name}
      placeAddress={shortPlace.address}
      placeDeliverySlug={deliverySlug.slug}
      burnSoon={burnSoon}
      totalBalance={totalBalance}
      currentBalance={currentBalance}
      conditionsLink={conditionsLink}
      upToOrders={upToOrders}
      leftDays={moment(applyBefore).startOf('day').diff(moment(), 'days') + 1}
      bonusesApplied={!!appliedAt}
      untilDateString={moment(applyBefore).format('D MMMM')}
      onClick={handleClick}
    />
  )
}
