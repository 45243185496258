export const parseYaFormWidgetEvent = (ev: MessageEvent) => {
  try {
    if (typeof ev.data !== 'string') {
      return null
    }

    if (ev.data === 'ping') {
      return {type: 'ping' as const}
    }

    const parsedData = JSON.parse(ev.data) as Record<string, unknown>

    if ('answer_key' in parsedData) {
      return {
        type: 'sent' as const,
        answer_key: parsedData.answer_key as string
      }
    }

    return null
  } catch {
    return null
  }
}
