import {useI18n} from '@eda-restapp/i18n'
import {
  CrossLIcon,
  Modal,
  Typography,
  RestaurantDelivery,
  useIsMobileStrict,
  OutsideLink,
  Button,
  SurgeM,
  POIPinFill,
  WalletFill
} from '@eda-restapp/ui'
import type {FC} from 'react'

import {DeliveryFullscreenBadge} from '../DeliveryFullscreenBadge/DeliveryFullscreenBadge'
import {DeliveryFullscreenCard} from '../DeliveryFullscreenCard/DeliveryFullscreenCard'

import styles from './DeliveryFullscreen.module.css'

export type DeliveryFullscreenProps = {
  isOpened: boolean
  aboutImage: string
  questionsLink: string
  footerButton: string
  onClose: () => void
}

export const DeliveryFullscreen: FC<DeliveryFullscreenProps> = ({
  isOpened,
  aboutImage,
  questionsLink,
  footerButton,
  onClose
}) => {
  const isMobile = useIsMobileStrict()
  const {t, tRaw} = useI18n()

  return (
    <Modal open={isOpened} className={styles.wrapper} containerClassName={styles.container}>
      <div className={styles.header}>
        <Button.Icon variant='light' size='m' round={isMobile} shadow={isMobile} onClick={onClose}>
          <CrossLIcon />
        </Button.Icon>
      </div>

      <Modal.Content className={styles.content}>
        <div className={styles.intro}>
          <div className={styles.introBadges}>
            <DeliveryFullscreenBadge
              text={t('orders.delivery-fullscreen.intro-first-badge', 'Для доставки силами ресторана')}
              adornment={<RestaurantDelivery />}
            />

            <DeliveryFullscreenBadge
              text={t('orders.delivery-fullscreen.intro-second-badge', 'Для заказов в личном кабинете')}
              adornment={<RestaurantDelivery />}
            />
          </div>

          <div className={styles.introInfo}>
            <Typography variant='title2' thickness='bold'>
              {t('orders.delivery-fullscreen.intro-title', 'Вызывайте курьера одним кликом')}
            </Typography>

            <Typography variant='body2'>
              {t(
                'orders.delivery-fullscreen.intro-text',
                'Когда не успеваете доставлять заказы своими силами, вызывайте курьера-партнёра Яндекс Доставки — по кнопке в карточке заказа'
              )}
            </Typography>
          </div>
        </div>

        <div className={styles.about}>
          <DeliveryFullscreenCard
            paragraphs={[
              t(
                'orders.delivery-fullscreen.about-first-card-first-paragraph',
                'Скорость доставки и стоимость тарифа видно сразу — всё рассчитывается индивидуально для каждого заказа'
              )
            ]}
            adornment={<WalletFill color='white' fontSize={20} />}
            extraSpace={!isMobile}
          />

          <DeliveryFullscreenCard
            paragraphs={[
              t(
                'orders.delivery-fullscreen.about-second-card-first-paragraph',
                'Отслеживайте путь курьера в карточке заказа, а пользователь — у себя в приложении'
              )
            ]}
            adornment={<POIPinFill color='white' fontSize={20} />}
            extraSpace={!isMobile}
          />

          <DeliveryFullscreenCard
            paragraphs={[
              t(
                'orders.delivery-fullscreen.about-third-card-first-paragraph',
                'Заполнять ничего не нужно — данные о ресторане и пользователе передадим курьеру автоматически'
              )
            ]}
            adornment={<SurgeM color='white' fontSize={20} />}
            extraSpace={!isMobile}
          />

          <div className={styles.aboutImageContainer}>
            <img src={aboutImage} alt='Скриншот' className={styles.aboutImage} />
          </div>
        </div>

        <div className={styles.howTo}>
          <Typography variant='body2' thickness='bold' className={styles.howToTitle}>
            {t('orders.delivery-fullscreen.how-to-title', 'Как это работает?')}
          </Typography>

          <div className={styles.howToGridContainer}>
            <div className={styles.howToGrid}>
              <DeliveryFullscreenCard
                title={t('orders.delivery-fullscreen.how-to-first-card-title', 'Подключите услугу')}
                paragraphs={[
                  t(
                    'orders.delivery-fullscreen.how-to-first-card-first-paragraph',
                    'Оплата за работу курьера будет списываться с баланса в Яндекс Доставке — поэтому нужен аккаунт.'
                  ),
                  t(
                    'orders.delivery-fullscreen.how-to-first-card-second-paragraph',
                    'Если у вас ещё нет учётной записи в Яндекс Доставке — создадим её для вас. А если уже есть — можно использовать её.'
                  )
                ]}
                adornment={
                  <Typography color='main' variant='body2' thickness='bold'>
                    1
                  </Typography>
                }
                darkMode
              />

              <DeliveryFullscreenCard
                title={t('orders.delivery-fullscreen.how-to-second-card-title', 'Пополните баланс')}
                paragraphs={[
                  t(
                    'orders.delivery-fullscreen.how-to-second-card-first-paragraph',
                    'Внести деньги можно в любой момент — с вашего расчётного счёта.'
                  ),
                  t(
                    'orders.delivery-fullscreen.how-to-second-card-second-paragraph',
                    'Для первого вызова курьера воспользуйтесь запасным кошельком. Вызывайте сразу, а платите позже — можно уйти в минус максимум на 1000₽.'
                  )
                ]}
                adornment={
                  <Typography color='invert' variant='body2' thickness='bold'>
                    2
                  </Typography>
                }
              />

              <DeliveryFullscreenCard
                title={t('orders.delivery-fullscreen.how-to-third-card-title', 'Вызывайте курьеров')}
                paragraphs={[
                  t(
                    'orders.delivery-fullscreen.how-to-third-card-first-paragraph',
                    'Выбирайте оптимальные тарифы для каждого заказа — чтобы не переплачивать, но доставлять всё вовремя.'
                  )
                ]}
                adornment={
                  <Typography color='invert' variant='body2' thickness='bold'>
                    3
                  </Typography>
                }
                adornmentAtLeft={!isMobile}
              />
            </div>
          </div>
        </div>

        <div className={styles.questions}>
          <Typography variant='body2' thickness='bold'>
            {t('orders.delivery-fullscreen.questions-title', 'Остались вопросы?')}
          </Typography>

          <Typography variant='body2' thickness='regular' className={styles.questionsText}>
            {tRaw('orders.delivery-fullscreen.questions-text', 'Подробнее о Яндекс Доставке — в {outsideLink}', {
              outsideLink: (
                <OutsideLink href={questionsLink} target={'_blank'} className={styles.questionsLink}>
                  {tRaw('orders.delivery-fullscreen.questions-link', 'Подробнее о Яндекс Доставке — в Справке', {
                    value: 'Справке'
                  })}
                </OutsideLink>
              )
            })}
          </Typography>
        </div>
      </Modal.Content>

      <Modal.Footer className={styles.footer}>
        <OutsideLink className={styles.footerButtonContainer} href={footerButton} target='_blank'>
          <Button.Text variant='dark' fullWidth>
            {t('orders.delivery-fullscreen.footer-button', 'Подключить доставку')}
          </Button.Text>
        </OutsideLink>
      </Modal.Footer>
    </Modal>
  )
}
