import {useExp3} from '@eda-restapp/configs'
import {eventLogger, metrika} from '@eda-restapp/logger'
import {ChevronRightMIcon, useIsDesktopStrict} from '@eda-restapp/ui'
import {Collapse, Popover} from '@mui/material'
import cn from 'classnames'
import React, {useEffect, useRef, useState} from 'react'
import {NavLink} from 'react-router-dom'

import {Tooltip} from '@restapp/shared-ui'

import {usePathsMatch} from '../../../hooks'
import type {SidebarGroup, SidebarLink, SidebarIFrame} from '../Sidebar.types'
import SidebarNavElement from '../SidebarNavElement'

import style from './SidebarNavGroup.module.css'

export interface SidebarNavGroupProps {
  className?: string
  mini: boolean
  navGroup: SidebarGroup
  onLinkClick: () => void
}

// TODO: unit-tests
export const SidebarNavGroup: React.FC<SidebarNavGroupProps> = ({className, navGroup, mini, onLinkClick}) => {
  const isDesktop = useIsDesktopStrict()

  const newYearConfig = useExp3('restapp_new_year')

  const hasMatch = usePathsMatch(navGroup.children.map((link) => link.path + '/*'))
  const popoverAnchorRef = useRef<HTMLDivElement>(null)
  const [isExpanded, setIsExpanded] = useState<boolean>(!mini && (navGroup.defaultExpanded || false))

  const badgeVisible = !isExpanded || !navGroup.badgeSettings?.hideWhenExpanded

  useEffect(() => {
    if (mini) {
      setIsExpanded(false)
    }
  }, [mini])

  const toggleGroup = () => {
    eventLogger({
      name: 'toggle-sidebar-group',
      additional: {isSidebarCollapsed: mini, isExpanded: !isExpanded}
    })
    metrika({
      target: 'toggle_sidebar_group',
      params: {isSidebarCollapsed: mini, isExpanded: !isExpanded}
    })
    setIsExpanded(!isExpanded)
  }
  const hidePopover = () => setIsExpanded(false)

  const handlePopoverLinkClick = (link: SidebarLink | SidebarIFrame) => () => {
    eventLogger({name: 'click-sidebar-element', value: 'popover-link', additional: {path: link.path, label: link.name}})
    metrika({
      target: 'click_sidebar_element',
      params: {path: link.path, label: link.name, value: 'popover-link'}
    })
    hidePopover()
  }

  return (
    <div className={className}>
      <Tooltip
        classes={{tooltip: style.tooltip}}
        title={navGroup.label}
        disableHoverListener={!mini || !isDesktop}
        disableFocusListener={!isDesktop}
        disableTouchListener={!isDesktop}
        placement='right'
        data-testid={`ui-sidebar-section-${navGroup.label}` /*UI | Новый сайдбар. Секция с группированными разделами*/}
      >
        <div
          ref={popoverAnchorRef}
          className={cn(style.groupHead, {
            [style.groupHeadActive]: hasMatch || (mini && isExpanded),
            [style.paddingWhenMini]: mini,
            [style.newYearGroundHead]: newYearConfig.enabled
          })}
          style={{color: navGroup.color}}
          onClick={toggleGroup}
        >
          <div data-testid={`sidebar-group-${navGroup.label}`} className={style.label}>
            <div className={style.icon}>{navGroup.icon}</div>
            {!mini && navGroup.label}
          </div>
          {badgeVisible && navGroup.badge}
          {!mini && (
            <ChevronRightMIcon fontSize='20px' className={cn(style.chevron, {[style.chevronOpened]: isExpanded})} />
          )}
        </div>
      </Tooltip>
      <Collapse in={!mini && isExpanded}>
        {navGroup.children.map((link) => (
          <SidebarNavElement
            className={style.linksSpacing}
            key={link.path}
            path={link.path}
            label={mini ? '' : link.name}
            badge={link.badge}
            onClick={onLinkClick}
            color={link.color}
          />
        ))}
      </Collapse>
      <Popover
        classes={{
          paper: cn(style.miniContextMenu, {[style.newYearMiniContextMenu]: newYearConfig.enabled})
        }}
        open={mini && isExpanded}
        anchorEl={popoverAnchorRef.current}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        elevation={0}
        onClose={hidePopover}
      >
        {navGroup.children.map((link) => (
          <NavLink
            key={link.path}
            className={cn(
              style.groupLinkInPopover,
              {[style.newYearGroupLinkInPopover]: newYearConfig.enabled},
              style.linksSpacing
            )}
            to={link.path}
            onClick={handlePopoverLinkClick(link)}
          >
            {link.name}
            {link.badge}
          </NavLink>
        ))}
      </Popover>
    </div>
  )
}
