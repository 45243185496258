import {eventLogger} from '@eda-restapp/logger'
import cn from 'classnames'
import React from 'react'

import styles from './SwitchTabs.module.css'

type Tab = {
  label: string
  value: string
}

export type SwitchTabsProps = {
  value: string
  tabs: Tab[]
  slug?: string
  className?: string
  onChange: (value: string) => void
}

export const SwitchTabs: React.FC<SwitchTabsProps> = ({value, tabs, slug = 'tabs-conditions', className, onChange}) => {
  const onChangeHandler = (tab: Tab) => () => {
    onChange(tab.value)
    eventLogger({name: slug, value: tab.value, additional: {type: 'tab-click', label: tab.label}})
  }

  return (
    <div className={cn(styles.tabs, className)}>
      <div className={styles.container}>
        {tabs.map((tab) => (
          <div
            key={tab.value}
            role='tab'
            onClick={onChangeHandler(tab)}
            className={cn(styles.tab, value === tab.value && styles.tabSelected)}
          >
            {tab.label}
          </div>
        ))}
      </div>
    </div>
  )
}
