import {useExp3} from '@eda-restapp/configs'
import {useChannelSubscription, placesChannel} from '@eda-restapp/microfrontend'
import moment from 'moment'
import React, {type FC, useMemo, useState} from 'react'

import {useHeavyPlaces, useOldHeavyPlaces} from '@restapp/core-places'
import type {FullPlaceType, ShortRestaurant} from '@restapp/shared-api'
import {useBusyModeModal, isSelfRegComplete, isSelfRegPending} from '@restapp/shared-places'

import {PlaceStatusList} from './PlaceStatusList'

export const PlaceStatusListContainer: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const placeConfig = useExp3('restapp_place')

  useChannelSubscription(placesChannel, (msg) => {
    if (msg.type === 'open-places-control') {
      setIsModalOpen(true)
    }
  })

  const disabledHeavyPlacesQuery = useHeavyPlaces({
    enabled: placeConfig.newRestaurantSearchHandle,
    filter: 'disabled',
    limit: 999
  })
  const enabledHeavyPlacesQuery = useHeavyPlaces({enabled: placeConfig.newRestaurantSearchHandle, filter: 'enabled'})
  const oldHeavyPlacesQuery = useOldHeavyPlaces({enabled: !placeConfig.newRestaurantSearchHandle})
  const busyModeModal = useBusyModeModal()

  const oldPlacesQuerySortedList = useMemo(() => {
    const disabledPlaces: ShortRestaurant[] = []
    const busyModeEnabledPlaces: ShortRestaurant[] = []
    const selfRegPendingplaces: ShortRestaurant[] = []
    const selfRegCompletePlaces: ShortRestaurant[] = []
    const enabledPlaces: ShortRestaurant[] = []

    oldHeavyPlacesQuery.places.forEach((place) => {
      if (!place.is_available && !isSelfRegComplete(place) && !isSelfRegPending(place)) {
        disabledPlaces.push(place)
      } else if (place.busy_mode?.enabled) {
        busyModeEnabledPlaces.push(place)
      } else if (isSelfRegPending(place)) {
        selfRegPendingplaces.push(place)
      } else if (isSelfRegComplete(place)) {
        selfRegCompletePlaces.push(place)
      } else if (place.is_available) {
        enabledPlaces.push(place)
      }
    })

    return [
      ...disabledPlaces,
      ...busyModeEnabledPlaces,
      ...selfRegPendingplaces,
      ...selfRegCompletePlaces,
      ...enabledPlaces
    ]
  }, [oldHeavyPlacesQuery.places])

  const placesSortByBlockFinishDate = (a: FullPlaceType, b: FullPlaceType) => {
    const firstPlaceEndBlockDate = moment(b.blocks?.[0].finished_at)
    const secondPlaceEndBlockDate = moment(a.blocks?.[0].finished_at)

    return secondPlaceEndBlockDate.diff(firstPlaceEndBlockDate)
  }

  const sortedPlaces = useMemo(() => {
    const sortedDisabledPlaces = disabledHeavyPlacesQuery.places.sort(placesSortByBlockFinishDate)

    return [...sortedDisabledPlaces, ...enabledHeavyPlacesQuery.places]
  }, [disabledHeavyPlacesQuery.places, enabledHeavyPlacesQuery.places])

  const onRefetch = async () => {
    if (placeConfig.newRestaurantSearchHandle) {
      await enabledHeavyPlacesQuery.refetch()
    } else if (oldHeavyPlacesQuery.refetch) {
      await oldHeavyPlacesQuery.refetch()
    }
  }

  return (
    <PlaceStatusList
      canLoadMorePlaces={!!enabledHeavyPlacesQuery.hasNextPage}
      isFetchingNextPlaceQueryPage={enabledHeavyPlacesQuery.isLoading || enabledHeavyPlacesQuery.isFetchingNextPage}
      isOpen={isModalOpen}
      onBusyModeButtonClick={(placeId: number) => busyModeModal.open(placeId)}
      places={placeConfig.newRestaurantSearchHandle ? sortedPlaces : (oldPlacesQuerySortedList as FullPlaceType[])}
      heavyPlacesQueryLoadMore={enabledHeavyPlacesQuery.fetchNextPage}
      onRefetch={onRefetch}
      setIsOpen={setIsModalOpen}
    />
  )
}
