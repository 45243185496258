import {useExp3} from '@eda-restapp/configs'
import React, {type FC} from 'react'
import {useQueryState} from 'react-router-use-location-state'

import {useHeavyPlaces} from '@restapp/core-places'
import {useSupportChat} from '@restapp/main-chats/hooks/useSupportChat'

import {BlockDescriptionModal} from './BlockDescriptionModal'

export const BlockDescriptionModalContainer: FC = () => {
  const [blockInfoPlaceId, setblockInfoPlaceId] = useQueryState('blockInfoPlaceId', '')

  const placeBlocksConfig = useExp3('restapp_place_blocks')
  const {getPlaceById} = useHeavyPlaces({enabled: true, limit: 999})
  const {openNewSupportChat} = useSupportChat()

  const placeBlockInfo = blockInfoPlaceId && getPlaceById(Number(blockInfoPlaceId))?.blocks?.[0]

  if (!placeBlockInfo || placeBlocksConfig.partnerRequestBlocksTypeList.includes(placeBlockInfo.reason)) {
    return null
  }

  const isNewAutostopInfoModalEnabled =
    !!placeBlockInfo?.reason && placeBlocksConfig.autostopBlocksTypeList.includes(placeBlockInfo.reason)
  const isBlockTypeInInfinityList =
    !!placeBlockInfo?.reason && placeBlocksConfig.infinityBlocksTypeList.includes(placeBlockInfo.reason)
  const isSupportButtonEnabled =
    !!placeBlockInfo?.reason && placeBlocksConfig.supportButtonEnabledBlocksTypeList.includes(placeBlockInfo.reason)

  const handleHelpButtonClick = () => {
    openNewSupportChat({place_id: Number(blockInfoPlaceId)})
  }

  return (
    <BlockDescriptionModal
      blockInfo={placeBlockInfo}
      isAutostopInfo={isNewAutostopInfoModalEnabled}
      isBlockTypeInInfinityList={isBlockTypeInInfinityList}
      isSupportButtonEnabled={isSupportButtonEnabled}
      isSupportLinkEnabled={
        !!placeBlockInfo?.reason &&
        !!placeBlocksConfig.descriptionModalTitleSupportLinks[placeBlockInfo?.reason]?.enabled
      }
      onHelpButtonClick={handleHelpButtonClick}
      onModalClose={() => setblockInfoPlaceId('')}
    />
  )
}
