import {useExp3} from '@eda-restapp/configs'
import {useState} from 'react'

import type {BonusesCampaign} from '@restapp/shared-api/types/promotion'

import {useBonusesCampaigns} from '../../../../../hooks/useBonusesCampaigns'

type UseLoadBonusesCampaignsProps = {
  campaignsStatus: 'new' | 'accepted'
  placesIds?: number[]
}

export const useLoadBonusesCampaigns = ({campaignsStatus, placesIds}: UseLoadBonusesCampaignsProps) => {
  const promotionConfig = useExp3('restapp_promotion')
  const [lastBalanceId, setLastBalanceId] = useState<string>()
  const [lastBurnedDownAt, setLastBurnedDownAt] = useState<string>()
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [campaigns, setCampaigns] = useState<BonusesCampaign[]>()

  const bonusesCampaigns = useBonusesCampaigns({
    campaignsStatus,
    limit: promotionConfig.cpaBonuses.campaignsFetchLimit,
    keepPreviousData: true,
    balanceId: lastBalanceId,
    burnedDownAt: lastBurnedDownAt,
    placesIds,
    onSuccess: (data) => {
      const isFirstCampaignsFetch = !campaigns

      if (data.campaigns.length === 0) {
        setCanLoadMore(false)

        if (isFirstCampaignsFetch) {
          setCampaigns([])
        }
      } else {
        const lastDataCampaignsBalanceId = data.campaigns[data.campaigns.length - 1].balance_id
        const lastCampaignsBalanceId = campaigns?.[campaigns.length - 1]?.balance_id

        const notEqualLastCampaignsBalanceId = lastCampaignsBalanceId !== lastDataCampaignsBalanceId

        if (notEqualLastCampaignsBalanceId || isFirstCampaignsFetch) {
          setCanLoadMore(true)
          setCampaigns([...(campaigns ?? []), ...data.campaigns])
        }
      }
    }
  })

  const handleLoadMore = () => {
    if (campaigns) {
      const lastCampaign = campaigns[campaigns.length - 1]

      setLastBalanceId(lastCampaign.balance_id)
      setLastBurnedDownAt(lastCampaign.burned_down_at)
    }
  }

  return {
    isLoading: bonusesCampaigns.isLoading,
    isFetched: bonusesCampaigns.isFetched,
    campaigns,
    canLoadMore: canLoadMore && !!campaigns && campaigns?.length >= promotionConfig.cpaBonuses.campaignsFetchLimit,
    loadMore: handleLoadMore
  }
}
