import {useExp3} from '@eda-restapp/configs'
import {useDistinctInterval} from '@eda-restapp/hooks'
import {eventLogger, metrika} from '@eda-restapp/logger'
import {usePermission} from '@eda-restapp/permissions'
import moment from 'moment'
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import type {WithPortalProps} from '@restapp/core-legacy/hocs/withPortals'
import {withPortals} from '@restapp/core-legacy/hocs/withPortals'
import {NewsModal} from '@restapp/shared/components'

import CriticalMessageWidget from '../components/CriticalMessageWidget/CriticalMessageWidget'
import NotificationModal from '../components/Notifications/NotificationModal/NotificationModal'
import {useNews} from '../hooks/useNews'
import {useNotifications} from '../hooks/useNotifications'
import {usePool} from '../hooks/usePool'
import {useSurvey} from '../hooks/useSurvey'
import {useSurveyModal} from '../hooks/useSurveyModal'
import {selectWidgetShowState} from '../selectors'
import {setWidgetShowState} from '../slice'
import {isNews, isNotification, isSurvey} from '../types'

import {useCriticalMessageWidgetContainerStyles} from './CriticalMessageWidgetContainer.style'
import useWidgetUrlResolver from './useWidgetUrlResolver'

// TODO: https://st.yandex-team.ru/EDARESTAPP-9218
function CriticalMessageWidgetContainer({openPortal}: WithPortalProps) {
  const {classes: c} = useCriticalMessageWidgetContainerStyles()
  const widgetUrlResolver = useWidgetUrlResolver()
  const widgetShowState = useSelector(selectWidgetShowState)
  const dispatch = useDispatch()

  const communicationsConfig = useExp3('restapp_communications')
  const surveyTimeInterval = communicationsConfig.surveyRepeatIntervalHours
  const surveyShouldDisplayAfterTime = communicationsConfig.surveyShouldDisplayAfterTime

  const {survey, surveyMethods} = useSurvey()
  const surveyModal = useSurveyModal()
  const pool = usePool()
  const news = useNews()
  const notification = useNotifications()

  // добавил проверку на та что опрос не скрыт
  // это работает примерно так если опрос скрыт показывается следующий по важности блок или ничего
  const message =
    !!survey && survey.status !== 'read' && (widgetShowState[survey.id] ?? false) ? survey : pool.forWidget

  const canViewSurveys = usePermission('permission.custom.critical_message_surveys')
  const [openedModal, setOpenedModal] = useState<'news' | 'survey' | 'notification' | null>(null)

  const handleClose = useCallback(() => {
    if (!message || !message.widget) {
      return
    }

    dispatch(setWidgetShowState({id: message.id, showState: false}))

    if (isNotification(message)) {
      notification.markRead(message.id)
      return
    }

    if (isNews(message)) {
      news.markRead(message.id)
      return
    }

    if (isSurvey(message)) {
      const metaInfo = surveyMethods.getSurveyMetaInfo(message.id)

      if (!metaInfo) {
        surveyMethods.setSurveyMetaInfo(message.id, {
          remainingShowsCount: message.info.show_count_after_closed || 0,
          nextNearestShowDate: moment().add(surveyTimeInterval, 'hours').toISOString()
        })

        return
      }

      const canShowSurvey = surveyMethods.checkCanShowSurvey(message, metaInfo)

      if (canShowSurvey) {
        const remainingShowsCount = metaInfo.remainingShowsCount - 1

        surveyMethods.setSurveyMetaInfo(message.id, {
          remainingShowsCount,
          nextNearestShowDate: moment().add(surveyTimeInterval, 'hours').toISOString()
        })

        if (remainingShowsCount <= 0) {
          surveyMethods.surveyMarkRead(message.id)
        }
      }
    }
  }, [dispatch, message, news, notification, surveyMethods, surveyTimeInterval])

  const closeModal = () => setOpenedModal(null)

  const handleClick = useCallback(() => {
    if (!message) {
      return
    }

    const url = message.widget?.url

    if (url && widgetUrlResolver.isSupportedUrl(url)) {
      eventLogger({
        name: 'resolve_widget_url',
        value: url,
        additional: {block: 'communications_widget', service: 'communications'}
      })
      widgetUrlResolver.resolveUrl(url)

      return
    }

    if (isNews(message)) {
      setOpenedModal('news')
    } else if (isSurvey(message)) {
      surveyModal.open()
    } else if (isNotification(message)) {
      openPortal(<NotificationModal notification={message} />)
    }
  }, [message, openPortal, surveyModal, widgetUrlResolver])

  useEffect(() => {
    if (!message || !message.widget) {
      return
    }

    metrika({
      target: 'show_notification_widget_crit',
      params: {
        id: message.id,
        page: location.pathname
      }
    })
  }, [message])

  const shouldDisplaySurvey = useDistinctInterval(() => {
    if (!survey) {
      return false
    }

    const metaInfo = surveyMethods.getSurveyMetaInfo(survey.id)

    const shouldDisplay = !metaInfo || surveyMethods.checkCanShowSurvey(survey, metaInfo)

    if (shouldDisplay) {
      dispatch(setWidgetShowState({id: survey.id, showState: true}))
    }

    return shouldDisplay
  }, surveyShouldDisplayAfterTime)

  if (!message?.widget) {
    return null
  }

  const showWidget = widgetShowState[message.id] ?? true
  if (!showWidget) {
    return null
  }

  if (isSurvey(message) && (!canViewSurveys || !shouldDisplaySurvey)) {
    return null
  }

  return (
    <>
      <CriticalMessageWidget
        className={c.widget}
        variant={message.type}
        widget={message.widget}
        onClick={handleClick}
        onClose={handleClose}
      />

      {isNews(message) && (
        <NewsModal
          id={message.id}
          open={openedModal === 'news'}
          isStory={message.content.content_type === 'story'}
          title={message.preview.title}
          pages={message.content.pages}
          onClose={() => {
            if (message.status === 'published') {
              news.markRead(message.id)
            }
            closeModal()
          }}
        />
      )}
    </>
  )
}

export default withPortals(CriticalMessageWidgetContainer)
