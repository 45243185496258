import {Typography} from '@eda-restapp/ui'
import cn from 'classnames'
import type {FC, ReactNode} from 'react'
import React from 'react'

import styles from './DeliveryFullscreenCard.module.css'

export type DeliveryFullscreenCardProps = {
  title?: string
  paragraphs: string[]
  adornment: ReactNode
  darkMode?: boolean
  adornmentAtLeft?: boolean
  extraSpace?: boolean
}

export const DeliveryFullscreenCard: FC<DeliveryFullscreenCardProps> = ({
  title,
  paragraphs,
  adornment,
  darkMode,
  adornmentAtLeft,
  extraSpace
}) => {
  return (
    <div className={cn(styles.container, adornmentAtLeft && styles.rowContainer, darkMode && styles.darkModeContainer)}>
      <div className={cn(styles.adornmentContainer, darkMode && styles.darkModeAdornmentContainer)}>{adornment}</div>

      <div className={cn(styles.innerContainer, extraSpace && styles.innerContainerWithExtraSpace)}>
        {!!title && (
          <Typography variant='body2' lineHeight='tight' thickness='medium' color={darkMode ? 'invert' : 'main'}>
            {title}
          </Typography>
        )}

        {paragraphs.map((paragraph) => {
          return (
            <Typography
              variant='caption1'
              lineHeight='normal'
              thickness='regular'
              color={darkMode ? 'invert' : 'main'}
              key={paragraph}
            >
              {paragraph}
            </Typography>
          )
        })}
      </div>
    </div>
  )
}
