import {makeStyles} from 'tss-react/mui'
import {keyframes} from 'tss-react'

const useStyles = () =>
  ({
    root: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },

    absolute: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },

    spinner: {
      display: 'inline-block',
      animation: `${keyframes`
      from {
        transform: rotateZ(0deg);
      }
      to {
        transform: rotateZ(720deg);
      }
      `} 1500ms infinite`,
      animationDirection: 'alternate',
      animationTimingFunction: 'cubic-bezier(0.42,0,0.58,1)'
    },
    caption: {
      maxWidth: '80vw',
      width: 'max-content',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, calc(50% + 16px))',
      opacity: 0,
      animation: `${keyframes`
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
        `} 1s 1 3s forwards`
    }
  }) as const

export default makeStyles({name: 'Spinner'})(useStyles)
