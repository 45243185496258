import type {FC} from 'react'
import {Refresh, Button} from '@eda-restapp/ui'
import React, {useState} from 'react'
import Spinner from '../Spinner'
import {delay} from '@restapp/shared/utils'

import {withStyles} from 'tss-react/mui'

import {eventLogger} from '@eda-restapp/logger'

type RefreshButtonProps = {
  className?: string
  classes?: Partial<Record<keyof typeof styles, string>>
  onUpdate: () => Promise<any>
  slug?: string
} & React.HTMLProps<HTMLDivElement>

const RefreshButton: FC<RefreshButtonProps> = (props) => {
  const {classes: c = {}} = props
  const [loading, setLoading] = useState(false)

  const onRefresh = async () => {
    setLoading(true)
    await Promise.all([props.onUpdate(), delay(2000)])
    setLoading(false)
    eventLogger({name: 'refresh_button', value: props.slug})
  }

  return (
    <div className={c.root} data-testid={'ui-refresh-button' /*UI | Refresh button*/}>
      {loading ? (
        <Spinner classes={{root: c.spinner}} size={23} />
      ) : (
        <Button.Icon size='m' variant='secondary' onClick={onRefresh}>
          <Refresh fontSize={25} />
        </Button.Icon>
      )}
    </div>
  )
}

const styles = {
  root: {
    width: 40,
    minWidth: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    background: '#EDF0F1',
    borderRadius: 8
  },
  spinner: {
    alignSelf: 'center'
  }
} as const

export default withStyles(RefreshButton, styles)
