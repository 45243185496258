import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'

import {useApiQuery} from '@restapp/core-api'

export function useSupportChatTopics() {
  const supportMultiChatConfig = useExp3('restapp_support_multi_chat')
  const {t} = useI18n()

  const allOption = {
    title: t('main-chats.chats-filters-modal.vse-temi', 'Все темы'),
    slug: 'all'
  }

  const topicsQuery = useApiQuery({
    url: '/4.0/restapp-front/support_chat/v2/topics',
    method: 'GET',
    enabled: !!supportMultiChatConfig.useNewTopicsListHadle,
    select: (data) => prependOption(data, allOption)
  })

  const topicsQueryOld = useApiQuery({
    url: '/4.0/restapp-front/support_chat/v1/topics',
    method: 'GET',
    enabled: !supportMultiChatConfig.useNewTopicsListHadle,
    select: (data) => prependOption(convertResponse(data), allOption)
  })

  return supportMultiChatConfig.useNewTopicsListHadle ? topicsQuery : topicsQueryOld
}

function convertResponse(data: {topics: string[]}) {
  return {
    topics: data.topics.map((topic) => {
      return {
        title: topic,
        slug: topic
      }
    })
  }
}

function prependOption<T extends {topics: {title: string; slug: string}[]}>(data: T, option: T['topics'][number]): T {
  return {
    ...data,
    topics: [option, ...data.topics]
  }
}
