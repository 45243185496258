import type {ReactNode} from 'react'
import React, {useEffect, useRef} from 'react'
import cn from 'classnames'

import {withStyles} from 'tss-react/mui'
import type {CloseCallback} from '@restapp/core-legacy/hocs/withPortals'
import HideBodyScroll from '@restapp/shared/components/HideBodyScroll'

type ChildrenProps = {close: CloseCallback<void | boolean>}
type OuterProps = {
  className?: string
  classes?: Partial<
    Record<'root' | 'childContainer' | 'backdrop' | 'outsideArea' | 'container' | 'content' | 'visible', string>
  >
} & {
  children: ReactNode | ((props: ChildrenProps) => ReactNode)
  visible: boolean
  close(reason?: any, force?: boolean): void
  onMount?(): void
  onShow?(): void
  onHide?(): void
}
type InnerProps = OuterProps

const ANIMATION_TIME = 250

const renderChildren = (children: OuterProps['children'], props: ChildrenProps): ReactNode =>
  typeof children === 'function' ? (children as any)(props) : children

const Modal = ({classes: c = {}, children, visible, close, onMount, onHide, onShow}: InnerProps) => {
  const animationTimer = useRef<number>()
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const mountTimer = window.setTimeout(() => onMount?.(), 15)
    // Подписываемcя не через React, т.к. React подписывается на touchstart с флагом passive: true
    // Из-за этого пропускаются клики на элементы за backdrop
    contentRef.current!.addEventListener('touchstart', handleTouchStart)

    return () => {
      if (animationTimer.current) {
        window.clearTimeout(animationTimer.current)
      }
      if (mountTimer) {
        window.clearTimeout(mountTimer)
      }
      contentRef.current?.removeEventListener('touchstart', handleTouchStart)
    }
  }, [])

  useEffect(() => {
    if (!visible) {
      enableAnimationTimer(onHide)
    } else {
      enableAnimationTimer(onShow)
    }
  }, [visible, onHide, onShow])

  const enableAnimationTimer = (callback?: () => void) => {
    window.clearTimeout(animationTimer.current!)

    if (!callback) {
      return
    }

    animationTimer.current = window.setTimeout(callback, ANIMATION_TIME + 15)
  }

  const handleTouchStart = (event: TouchEvent) => {
    const targetElem = event.target as Element

    if (targetElem === contentRef.current!) {
      event.preventDefault()
      event.stopImmediatePropagation()
      close()
    }
  }

  return (
    <div className={cn(c.root, visible && c.visible)}>
      <HideBodyScroll />
      <div className={cn(c.backdrop, c.childContainer)} />
      <div className={cn(c.container, c.childContainer)}>
        <div ref={contentRef} className={c.content}>
          {renderChildren(children, {close})}
        </div>
      </div>
    </div>
  )
}

export default withStyles(
  Modal,
  (theme, props, classes) =>
    ({
      root: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },

      childContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0
      },

      backdrop: {
        // composes: '$childContainer',
        zIndex: 1,
        background: 'rgba(0, 0, 0, 0.5)',
        transition: `opacity ${ANIMATION_TIME}ms`
      },

      outsideArea: {
        composes: '$childContainer'
      },

      container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // composes: '$childContainer',
        textAlign: 'center',
        zIndex: 2,
        transition: `all ${ANIMATION_TIME}ms linear`,
        transform: 'translateY(50px)',
        '&:after': {
          content: '""',
          display: 'inline-block',
          width: 0,
          height: '100%',
          verticalAlign: 'middle'
        }
      },

      content: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'initial',
        position: 'relative',
        zIndex: 1
      },

      visible: {
        [`& .${classes.backdrop}`]: {
          opacity: 1
        },
        [`& .${classes.container}`]: {
          opacity: 1,
          transform: 'translateY(0)'
        }
      }
    }) as const
)
