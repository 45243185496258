import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'

import {PLACE_STATUS} from '@restapp/main-places/types/status'
import type {FullPlaceType} from '@restapp/shared-api'
import {getOldPlaceStatus} from '@restapp/shared-places'
import {usePlaceStatus} from '@restapp/shared-places/hooks/usePlaceStatus'

import {usePlaceBlockFinishAtDateLabel} from './usePlaceBlockFinishAtDateLabel'
import {usePlaceStatusWithErrorLabel} from './usePlaceStatusWithErrorLabel'

type UsePlaceCardStatusLabelProps = {
  place: FullPlaceType
  returnEnabledLabel?: boolean
}

export const usePlaceCardStatusLabel = ({place, returnEnabledLabel}: UsePlaceCardStatusLabelProps) => {
  const placeBlocksConfig = useExp3('restapp_place_blocks')
  const placeConfig = useExp3('restapp_place')

  const finishAtDateLabel = usePlaceBlockFinishAtDateLabel(place.blocks?.[0].finished_at)
  const placeStatusWithErrorLabel = usePlaceStatusWithErrorLabel(place)
  const placeStatus = usePlaceStatus(place)
  const {t} = useI18n()

  if (placeConfig.newRestaurantSearchHandle && !place.blocks?.length) {
    return {text: returnEnabledLabel ? t('main-places.utils.podklyuchen', 'Подключён') : '', error: false}
  }

  const blockFinishDate = placeConfig.newRestaurantSearchHandle
    ? finishAtDateLabel
    : place.disable_details?.available_at

  const blockFinishedAtLabel = blockFinishDate
    ? t('main-places.use-place-card-status-label.block-finish-time-label', 'до {finishDate}', {
        finishDate: finishAtDateLabel
      })
    : ''

  const placeBlockReason = place.blocks?.[0].reason

  if (
    placeConfig.newRestaurantSearchHandle &&
    (place.blocks?.[0].source === 'partner' ||
      (placeBlockReason && placeBlocksConfig.partnerRequestBlocksTypeList.includes(placeBlockReason)))
  ) {
    return {
      text: [t('main-places.use-place-card-status-label.partner-block-label', 'Отключён'), blockFinishedAtLabel].join(
        ' '
      ),
      error: true
    }
  }

  if (placeConfig.newRestaurantSearchHandle && finishAtDateLabel && placeStatus !== PLACE_STATUS.ACTIVATION_PENDING) {
    return {
      text: [
        t('main-places.use-place-card-status-label.service-block-label', 'Отключён сервисом'),
        blockFinishedAtLabel
      ].join(' '),
      error: true
    }
  }

  const resultPlaceStatus = placeConfig.newRestaurantSearchHandle ? placeStatus : getOldPlaceStatus(place)

  switch (resultPlaceStatus) {
    case PLACE_STATUS.SELF_REG_PENDING:
      return {text: t('main-places.utils.dannie-proveryayutsya', 'Данные проверяются'), error: false}

    case PLACE_STATUS.SELF_REG_COMPLETE:
      return {text: t('main-places.utils.restoran-gotov-k-vklyucheniyu', 'Ресторан готов к включению'), error: false}

    case PLACE_STATUS.DISABLED_BY_AUTOSTOP:
    case PLACE_STATUS.DISABLED_BY_VENDOR:
      return placeStatusWithErrorLabel

    case PLACE_STATUS.DISABLED_BY_YANDEX:
      return {text: t('main-places.utils.zaprosit-vklyuchenie', 'Запросить включение'), error: false}

    case PLACE_STATUS.CAN_NOT_BE_ENABLED:
      return {text: t('main-places.utils.viklyuchen', 'Выключен'), error: false}

    case PLACE_STATUS.ACTIVATION_PENDING:
      return {
        text: t('main-places.utils.zapros-na-vklyuchenie-otpravlen', 'Запрос на включение отправлен'),
        error: false
      }

    case PLACE_STATUS.DISABLED:
      return {
        text: t('main-places.use-place-card-status-label.service-block-label', 'Отключён сервисом'),
        error: true
      }

    case PLACE_STATUS.BUSY_MODE_ENABLED:
      return {
        text: t('main-places.utils.busy-mode-enabled', 'Высокая загрузка включена'),
        error: true
      }

    default:
      return {text: returnEnabledLabel ? t('main-places.utils.podklyuchen', 'Подключён') : '', error: false}
  }
}
