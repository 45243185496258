import {useI18n} from '@eda-restapp/i18n'
import {
  Box,
  DeliveryIcon,
  Typography,
  Clock as ClockIcon,
  ChevronRightMIcon,
  BonusIcon,
  OutsideLink
} from '@eda-restapp/ui'
import cn from 'classnames'
import type {FC} from 'react'

import {formatNumber} from '@restapp/shared/utils'

import type {PlaceCardProps} from '../../PlaceCard'
import {BonusesProgressBar} from '../BonusesProgressBar/BonusesProgressBar'
import {PlaceBonuses} from '../PlaceBonuses/PlaceBonuses'

import styles from './PlaceCardMobile.module.css'

export const PlaceCardMobile: FC<PlaceCardProps> = ({
  placeName,
  placeAddress,
  placeDeliverySlug,
  conditionsLink,
  bonusesApplied,
  currentBalance,
  totalBalance,
  burnSoon,
  onClick,
  untilDateString,
  leftDays,
  upToOrders,
  className
}) => {
  const {t, lang, tRaw} = useI18n()

  return (
    <Box
      data-testid='place-card'
      className={cn(
        styles.root,
        {
          [styles.rootBurning]: burnSoon && !bonusesApplied,
          [styles.rootBurningBottom]: burnSoon && !bonusesApplied
        },
        className
      )}
    >
      <Box className={cn(styles.button, {[styles.buttonWithLink]: bonusesApplied})} onClick={onClick}>
        {bonusesApplied ? (
          <Box flexDirection='column' gap='xs' pt='m'>
            <Box data-testid='place-info' gap='s' alignItems='center' pl='m'>
              <DeliveryIcon deliverySlug={placeDeliverySlug} />
              <Box flexDirection='column'>
                <Typography variant='body2' thickness='medium'>
                  {placeName}
                </Typography>
                <Typography variant='caption1'>{placeAddress}</Typography>
              </Box>
            </Box>
            <OutsideLink className={styles.linkInHeader} href={conditionsLink} target='_blank'>
              <Typography variant='caption1'>
                {t('promotion.bonuses-place-card.conditions-link', 'Условия акции')}
              </Typography>
            </OutsideLink>
          </Box>
        ) : (
          <Box alignItems='center' justifyContent='end'>
            <Box className={styles.marginRight12}>
              <PlaceBonuses total={totalBalance} burnSoon={burnSoon} />
            </Box>
            <Typography data-testid='up-to-orders' className={styles.minorText} variant='caption1' lineHeight='normal'>
              {tRaw('promotion.bonuses-place-card.up-to-orders', 'Можно оплатить {orderCnt}', {
                orderCnt: (
                  <span className={styles.minorTextPre}>
                    {t('promotion.bonuses-place-card.up-to-orders-orders-cnt', 'до {count} заказов', {
                      one: 'до {count} заказа',
                      some: 'до {count} заказов',
                      many: 'до {count} заказов',
                      count: upToOrders
                    })}
                  </span>
                )
              })}
            </Typography>
          </Box>
        )}
        <ChevronRightMIcon fontSize={24} />
      </Box>
      <Box px='m' pb='m' flexDirection='column'>
        <Box className={styles.divider} />
        <Box flexDirection='column' gap='s'>
          <Box flexDirection='column' gap='s'>
            {!bonusesApplied && (
              <Box gap='s' alignItems='center'>
                <DeliveryIcon deliverySlug={placeDeliverySlug} />
                <Box data-testid='place-info' flexDirection='column'>
                  <Typography variant='body2' thickness='medium'>
                    {placeName}
                  </Typography>
                  <Typography variant='caption1'>{placeAddress}</Typography>
                </Box>
              </Box>
            )}
            <Box data-testid='bonuses-date' gap='s' alignItems='center'>
              <Box className={styles.icon}>
                <ClockIcon className={styles.minorText} fontSize={16} />
              </Box>
              <Box>
                <Typography className={styles.minorText} variant='caption1'>
                  {t('promotion.bonuses-place-card.until-burned-down-date', 'До {date}', {
                    date: untilDateString
                  })}
                  {burnSoon && ' · '}
                </Typography>
                {burnSoon && (
                  <Typography className={styles.burningText} variant='caption1' thickness='medium'>
                    {t('promotion.bonuses-place-card.some-days-left', 'Осталось {count} день/дня/дней', {
                      one: 'Остался {count} день',
                      some: 'Осталось {count} дня',
                      many: 'Осталось {count} дней',
                      count: leftDays
                    })}
                  </Typography>
                )}
              </Box>
            </Box>
            {bonusesApplied ? (
              <Box data-testid='bonuses-count' flexDirection='column' gap='s'>
                <Box gap='s' alignItems='center'>
                  <Box className={styles.icon}>
                    <BonusIcon fontSize={16} />
                  </Box>
                  <Typography variant='caption1' thickness='medium'>
                    {formatNumber(Number(currentBalance), lang, {useGrouping: true}) +
                      ' / ' +
                      formatNumber(Number(totalBalance), lang, {useGrouping: true})}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <OutsideLink
                className={styles.linkInFooter}
                href={conditionsLink}
                target='_blank'
                style={{flexShrink: 0}}
              >
                <Typography variant='caption1'>
                  {t('promotion.bonuses-place-card.conditions-link', 'Условия акции')}
                </Typography>
              </OutsideLink>
            )}
          </Box>
          {bonusesApplied && (
            <BonusesProgressBar current={Number(currentBalance)} max={Number(totalBalance)} burnSoon={burnSoon} />
          )}
        </Box>
      </Box>
    </Box>
  )
}
