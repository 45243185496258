import {useI18n} from '@eda-restapp/i18n'
import {SettingsOutlineIcon, Button, Box} from '@eda-restapp/ui'
import {useState} from 'react'
import {useSelector} from 'react-redux'
import {makeStyles} from 'tss-react/mui'

import {MultiplePlacesDropdown, usePlaces} from '@restapp/core-places'
import {Header, HeaderRow, SidebarBurger} from '@restapp/main-layout'
import {useServiceBrand} from '@restapp/shared'
import RefreshButton from '@restapp/shared-ui/RefreshButton'

import {chatsSlice} from '../../slice/chatsSlice'
import NewChatButton from '../NewChatButton/NewChatButton'

import ChatsFiltersModal from './ChatsFiltersModal'

const useStyles = makeStyles()({
  changedFiltersCount: {
    borderRadius: '50%',
    backgroundColor: '#FCE45E',
    position: 'absolute',
    top: -4,
    right: -3,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '18px',
    height: 16,
    minWidth: 16
  },
  restaurantSelectMobile: {
    width: '100%',
    display: 'block'
  },
  yangoTitle: {
    fontFamily: 'Yango Headline'
  }
})

type ChatsHeaderMobile = {
  onCreateChat: (restaurantId: number) => void
  onRefresh: () => Promise<unknown>
}

function ChatsHeaderMobile({onCreateChat, onRefresh}: ChatsHeaderMobile) {
  const {t} = useI18n()
  const {classes: c, cx} = useStyles()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const serviceBrand = useServiceBrand()

  const {placesIds} = usePlaces()

  const dirtyCounter = useSelector(chatsSlice.selectors.selectFiltersDirtyCount)

  function handleClickFilters() {
    setIsModalOpen(true)
  }

  return (
    <>
      <Header>
        <HeaderRow stickyPosition={0}>
          <SidebarBurger />

          <Box justifyContent='space-between' alignItems='center' flexGrow={1}>
            <h1 className={cx({[c.yangoTitle]: serviceBrand === 'YangoEats'})}>
              {t('main-chats.chats-header-mobile.podderzhka', 'Поддержка')}
            </h1>

            <Box style={{gap: 12}}>
              <Button.Icon size='m' variant='light' onClick={handleClickFilters}>
                <SettingsOutlineIcon />
                {dirtyCounter > 0 && <span className={c.changedFiltersCount}>{dirtyCounter}</span>}
              </Button.Icon>

              <RefreshButton onUpdate={onRefresh} />

              <NewChatButton
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                onCreateChat={onCreateChat}
              />
            </Box>
          </Box>
        </HeaderRow>

        {placesIds.length > 1 && (
          <HeaderRow>
            <MultiplePlacesDropdown className={c.restaurantSelectMobile} />
          </HeaderRow>
        )}
      </Header>

      <ChatsFiltersModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  )
}

export default ChatsHeaderMobile
