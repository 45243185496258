import {useI18n} from '@eda-restapp/i18n'
import {Box, Button, Typography, useIsMobileStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import type {FC} from 'react'

import {TransparentCard} from './components/TransparentCard/TransparentCard'
import {WhiteCard} from './components/WhiteCard/WhiteCard'
import styles from './HeadSection.module.css'

type HeadSectionProps = {
  onRedirectToPromotion: () => void
}

export const HeadSection: FC<HeadSectionProps> = ({onRedirectToPromotion}) => {
  const isMobile = useIsMobileStrict()
  const {t} = useI18n()

  return (
    <Box
      p={isMobile ? 'm' : 'l'}
      pb={isMobile ? 'xl' : undefined}
      alignItems='center'
      flexDirection='column'
      className={styles.root}
    >
      <Typography
        variant={isMobile ? 'title3' : 'title2'}
        thickness='medium'
        lineHeight='normal'
        color='invert'
        className={cn(styles.text, styles.title)}
      >
        {t('promotion.head-section.title', 'У вас есть бонусы\n на привлечение заказов')}
      </Typography>
      <Typography mt='s' variant='body2' lineHeight='loose' color='invert' className={styles.text}>
        {t(
          'promotion.head-section.subtitle',
          'Начислены бонусы на рестораны для продвижения. Запускайте рекламные кампании за бонусы – это выгодно.'
        )}
      </Typography>
      <Box className={styles.cards} mt='m' mb='s' justifyContent='space-between'>
        <div>
          <TransparentCard
            className={styles.firstTransparentCard}
            title={t('promotion.head-section.additional-orders-for-promotion', 'Дополнительные заказы от продвижения')}
            badge={t('promotion.head-section.for-thirty-four-percent', 'до + 34 %')}
          />
          <TransparentCard
            className={styles.secondTransparentCard}
            title={t(
              'promotion.head-section.calculate-effective-personal-count',
              'Рассчитали эффективную персональную ставку'
            )}
            badge={t('promotion.head-section.activation-for-two-clicks', 'запуск за 2 клика')}
          />
        </div>
        <WhiteCard />
      </Box>
      <Button.Text
        data-testid='promotion-drawer-action-button'
        variant='dark'
        fullWidth
        onClick={onRedirectToPromotion}
      >
        {t('promotion.head-section.action-button', 'Потратить бонусы на продвижение')}
      </Button.Text>
    </Box>
  )
}
