import {metrika} from '@eda-restapp/logger'
import {useNavigate} from 'react-router'

import {getPromotionMainPath} from '@restapp/promotion/constants'

import {HeadSection} from './HeadSection'

export const HeadSectionContainer = () => {
  const navigate = useNavigate()

  const handleRedirectToPromotion = () => {
    metrika({target: 'bonuses_promo_drawer_click_spend_bonuses_on_promotion'})

    navigate(getPromotionMainPath())
  }

  return <HeadSection onRedirectToPromotion={handleRedirectToPromotion} />
}
