import {useI18n} from '@eda-restapp/i18n'
import {Typography, Box, BonusIcon} from '@eda-restapp/ui'
import cn from 'classnames'
import type {FC} from 'react'

import {formatNumber} from '@restapp/shared/utils'

import styles from './PlaceBonuses.module.css'

type RestaurantBonusesProps = {
  total: string
  burnSoon?: boolean
}

export const PlaceBonuses: FC<RestaurantBonusesProps> = ({total, burnSoon}) => {
  const {lang} = useI18n()

  return (
    <Box data-testid='place-bonuses' className={cn(styles.root, {[styles.rootToBurn]: burnSoon})}>
      <Typography variant='body1' thickness='bold'>
        {formatNumber(Number(total), lang, {useGrouping: true})}
      </Typography>
      <BonusIcon fontSize={16} />
    </Box>
  )
}
