import {useMutation} from '@tanstack/react-query'
import {useCallback} from 'react'

import type {VendorApi as Api} from '@restapp/shared-api'

import {useApiDeps} from './hooks/useApiDeps'
import type {ApiQueryKey} from './types'

type ResetOptions = {
  onSuccess: () => void
}

export default function useApiQueryReset<
  TUrl extends keyof Api = keyof Api,
  TMethod extends keyof Api[TUrl] = keyof Api[TUrl]
>(queryKey?: ApiQueryKey<TUrl, TMethod>) {
  const {queryClient} = useApiDeps()

  const resetMutation = useMutation({
    mutationFn: useCallback(() => queryClient.resetQueries(queryKey), [queryClient, queryKey])
  })

  const reset = useCallback(
    (opt?: ResetOptions) => resetMutation.mutate(undefined, {onSuccess: opt?.onSuccess}),
    [resetMutation]
  )

  return {reset, isInProgress: resetMutation.isLoading}
}
