import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {Box, CommentFillIcon} from '@eda-restapp/ui'
import {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {usePlaces} from '@restapp/core-places'
import {Button} from '@restapp/shared-ui/Button'

import {useSupportChat} from '../../hooks/useSupportChat'
import {chatsSlice} from '../../slice/chatsSlice'
import NewChatButton from '../NewChatButton'

import {useNoChatsStyles} from './NoChats.style'

function NoChats() {
  const {openNewSupportChat} = useSupportChat()
  const dispatch = useDispatch()

  const {
    footerCallCenterSupportInfo: {enabled: footerCallCenterSupportInfoEnabled}
  } = useExp3('restapp_support')
  const {t} = useI18n()
  const {classes: c} = useNoChatsStyles()

  useEffect(() => metrika({target: 'chats_empty_show'}), [])

  const {selectedPlaceIds, setSelectedPlaceIds, places} = usePlaces()

  const isAllPlacesSelected = selectedPlaceIds.length === places.length
  const isDirty = useSelector(chatsSlice.selectors.selectFiltersDirty) || !isAllPlacesSelected

  function resetHandler() {
    dispatch(chatsSlice.actions.resetFilters())
    setSelectedPlaceIds([])
  }

  return (
    <div data-testid='no-chats-screen' className={c.root}>
      <div data-testid={'no-chats-icon' /*Экран поддержки | Иконка при отсутствии чатов */} className={c.iconWrapper}>
        <CommentFillIcon />
      </div>
      {isDirty && (
        <div>
          <div
            data-testid={
              'no-chats-title-chat-not-found' /*Экран поддержки' | Описание отсутствия чатов по выбранным фильтрам */
            }
            className={c.title}
          >
            {t('main-chats.no-chats.po-vibrannim-filtram-obrashchenii', 'По выбранным фильтрам обращений')}
            <br />
            {t('main-chats.no-chats.v-podderzhku-net', 'в поддержку нет')}
          </div>
          <div
            data-testid={
              'no-chats-body-chat-not-found' /*Экран поддержки' | Описание отсутствия чатов по выбранным фильтрам, вторая строчка */
            }
          >
            {t(
              'main-chats.no-chats.sbroste-filtri-ili-sozdaite-novoe-ob',
              'Сбросьте фильтры или создайте новое обращение.'
            )}
          </div>
          <Box mt='l' justifyContent='center'>
            <Button data-testid='no-chats-screen-reset-filters-button' variant='contained' onClick={resetHandler}>
              {t('main-chats.no-chats.sbrosit-filtri', 'Сбросить фильтры')}
            </Button>
          </Box>
        </div>
      )}

      {!isDirty && (
        <div>
          <div
            data-testid={'no-chats-title' /*Экран поддержки | Заголовок экрана при отсутствии чатов */}
            className={c.title}
          >
            {t('main-chats.no-chats.poka-obrashchenii-v-podderzhku-net', 'Пока обращений в поддержку нет')}
          </div>
          <div data-testid={'no-chats-text' /*Экран поддержки | Текст экрана при отсутствии чатов */}>
            {footerCallCenterSupportInfoEnabled
              ? t(
                  'main-chats.no-chats.default-filters-subtitle',
                  'Измените фильтры, создайте новое обращение или позвоните.'
                )
              : t(
                  'main-chats.no-chats.zdes-mozhno-budet-naiti-vse-vashi-ob',
                  'Здесь можно будет найти все ваши обращения, прочитать историю сообщений и оценить работу поддержки.'
                )}
          </div>
          <Box mt='l' justifyContent='center'>
            <NewChatButton
              label={t('main-chats.no-chats.sozdat-obrashchenie', 'Создать обращение')}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              onCreateChat={(placeId) => {
                metrika({
                  target: 'chats_new_chat_click_no_chats'
                })

                openNewSupportChat({place_id: placeId})
              }}
            />
          </Box>
        </div>
      )}
    </div>
  )
}

export default NoChats
