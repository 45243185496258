import React, {memo} from 'react'
import {WebPush} from './webpush/WebPush'
import {ProcessClickedNotifications} from './ProcessClickedNotifications'
import {DisplayWebSocketNotifications} from './websocket/DisplayWebSocketNotifications'
import {isNativeVendor} from '@restapp/shared'
import AcknowledgePushNotification from './AcknowledgePushNotification'

export const Notifications = memo(() => (
  <>
    {!isNativeVendor && <WebPush Fallback={DisplayWebSocketNotifications} />}
    <ProcessClickedNotifications />
    <AcknowledgePushNotification />
  </>
))
