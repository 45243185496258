import type {CurrencyType} from './common'

export type PromoServiceType = 'eda' | 'dc'

export enum WEEKDAYS {
  MONDAY = 1,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY
}

/** Average delivery cost for one Place */
export interface AvgDeliveryFee {
  place_id: number
  avg_delivery_fee: MoneyValue
  avg_delivery_fee_double: number
  currency: CurrencyType
}

export interface MoneyValue {
  price_value: string
}

export interface PromoPlacePassedCheck {
  code: number
  title: string
  description: string
}

export interface PromoPlaceFailedCheck extends PromoPlacePassedCheck {
  short_description: string
}

export const COFINANCE_PROMO_TYPE = 'cofinance'
export type CofinancePromoType = typeof COFINANCE_PROMO_TYPE

export enum PromoEnum {
  GIFT = 'gift',
  DISCOUNT = 'discount',
  ONE_PLUS_ONE = 'one_plus_one',
  PLUS_FIRST_ORDERS = 'plus_first_orders',
  PLUS_HAPPY_HOURS = 'plus_happy_hours',
  FREE_DELIVERY = 'free_delivery',
  LOYALTY_DISCOUNT_RECENT = 'loyalty_discount_recent',
  LOYALTY_DISCOUNT_FORMER = 'loyalty_discount_former',
  LOYALTY_DISCOUNT_NEW = 'loyalty_discount_new',
  CART_DISCOUNT = 'cart_discount',
  CALENDAR = 'calendar'
}

export type PromoBaseType =
  | PromoEnum.CART_DISCOUNT
  | PromoEnum.DISCOUNT
  | PromoEnum.FREE_DELIVERY
  | PromoEnum.GIFT
  | PromoEnum.ONE_PLUS_ONE
  | PromoEnum.PLUS_FIRST_ORDERS
  | PromoEnum.PLUS_HAPPY_HOURS
  | PromoEnum.LOYALTY_DISCOUNT_FORMER
  | PromoEnum.LOYALTY_DISCOUNT_NEW
  | PromoEnum.LOYALTY_DISCOUNT_RECENT

export type PromoType = PromoBaseType | PromoEnum.CALENDAR

export interface CheckedPromoType<TPromoType extends PromoType = PromoType> {
  promo_type: TPromoType
  places_passed: Array<{
    place_id: number
    passed_checks: PromoPlacePassedCheck[]
  }>
  places_failed: Array<{
    place_id: number
    passed_checks: PromoPlacePassedCheck[]
    failed_checks: Array<PromoPlaceFailedCheck>
  }>
}

export type PromoSection = 'promos' | 'loyalty_program'

export interface PromoSettings<
  TPromoType extends PromoType = PromoType,
  TPromoSection extends PromoSection = PromoSection
> {
  type: TPromoType
  title: string
  description: string
  is_new: boolean
  is_multiple_places_allowed: boolean // Можно ли заводить акцию на несколько ресторанов
  can_show_stats?: boolean // Можно ли выбрать акцию в статистике

  can_create: boolean // Можно ли создать акцию, если false то не будем отображать в селекторе
  can_edit: boolean // Отображение кнопки редактировать. Только для старых акций. Метод patch
  can_pause: boolean // Отображение кнопки поставить на паузу. Только для старых акций. Используются ручки disable/enable
  can_stop: boolean // Отображение кнопки завершить акцию. Пока только для плюсовых

  // У каждой акции своя конфигурация
  configuration: MapPromoTypeToConfiguration[TPromoSection]
}

type PromoTab = {
  name: string
  types: PromoType[]
}

export interface PromoSettingsResponse {
  // Список доступных типов
  available: PromoSettings[]
  tabs: PromoTab[]
}

interface MinMaxLimits {
  minimum: number
  maximum: number
}

interface DaysSelector {
  days: number
  name: string
}

export interface AvailableShippingType {
  name: string
  value: string
}

type ServiceValue = {
  value: 'dc' | 'eda'
  name: string
}

interface BasePromoConfiguration {
  order_indexes?: number[]
  days_from_last_order?: DaysFromLastOrderOption[]
  available_shipping_types?: AvailableShippingType[]
  discount?: MinMaxLimits
  min_order_price?: MinMaxLimits
  available_services?: ServiceValue[]
}

export interface PromosSettingsConfiguration extends BasePromoConfiguration {
  item_ids?: {
    minItems: number
    disabled_categories: string[]
  }
  item_id?: {
    disabled_categories: string[]
  }
  cashback?: MinMaxLimits
}

interface LoyaltySettingsConfiguration extends BasePromoConfiguration {
  place_orders_counts?: Array<{
    name: string
    count: number
  }>
  place_days_from_last_order_till?: DaysSelector[]
  place_days_from_last_order_from?: DaysSelector[]
}

interface MapPromoTypeToConfiguration {
  ['promos']: PromosSettingsConfiguration
  ['loyalty_program']: LoyaltySettingsConfiguration
}

export interface DaysFromLastOrderOption {
  name: string
  days: number
}

export interface Promo {
  id: number
  status: PromoStatus
  name: string
  type: PromoType
  description: string
  starts_at: string
  ends_at: string
  new_version_at?: string
  place_ids: number[]

  bonuses: PromoBonus[]
  requirements: PromoRequirement[]
  schedule?: PromoSchedule[]

  budget_limit?: {
    currency: CurrencyType
    total_limit: MoneyValue
  }
  completion_reason?: 'end_time' | 'budget_limit' | 'user_request' | 'internal' | 'discount_conflict'
  /** Тип иконки, почти тоже что и тип акции */
  icon_type?: PromoType
  /** calendar offer */
  offer_id?: number
}

export const enum PromoStatus {
  // На беке new это new и approved
  NEW = 'new',
  APPROVED = 'approved',
  COMPLETED = 'completed',
  /** Завершается, недоступны никакие действия кроме «Посмотреть» */
  COMPLETING = 'completing',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  /** Создан драфт новой версии (аналог new, но после редактирования), недоступны никакие действия кроме посмотреть */
  UPDATE = 'update',
  /** Обновления запланированы (аналог approved, но после редактирования), действия доступны. */
  UPDATE_APPROVED = 'update_approved'
}

/**
 * То же но на беке
 * kPlus = 0,
 * kRating = 1,
 * kPhotoPercent = 2,
 * kIntegration = 3,
 * kPlaceType = 4,
 * kBusinessType = 5,
 * kSubscription = 6,
 * kConflict = 7
 */
export const enum PromoCheckCode {
  PLUS = 0,
  LOW_RATING = 1,
  LOW_MENU_PHOTO_PERCENT = 2,
  WRONG_INTEGRATION_ENGINE = 3,
  DELIVERY_TYPE = 4,
  BUSINESS_TYPE = 5,
  NO_SUBSCRIPTION = 6,
  HAS_CONFLICT = 7
}

export interface PromoSchedule {
  // Номер дня недели (1-7)
  day: WEEKDAYS
  // Начало действия акции (в минутах от полуночи).
  from: number
  // Окончание действия акции (в минутах от полуночи)
  to: number
}

export interface PromoRequirement {
  min_order_price?: number
  // ID категорий, на которые даётся скидка
  category_ids?: number[]
  // Блюда, на которые распространяется скидка/1+1
  item_ids?: string[]
  // На какие порядковые номера заказов должна срабатывать акция для нового пользователя
  order_indexes?: number[]
  // Сколько дней пользователь не делал заказ в ресторане, чтобы сработала акция
  days_from_last_order?: number
  // Выбранные типы доставки
  shipping_types?: string[]
  // На какие по счету заказы будет распространяться акция, по умолчанию только на первый заказ
  order_numbers?: number[]
  // На первый заказ в месте
  place_orders_counts?: number[]
  // Пользователь заказывал в пределах этих дней в ресторане
  place_days_from_last_order_till?: number
  // Пользователь не заказывал в пределах этих дней в ресторане
  place_days_from_last_order_from?: number
  // Сервисы, на которых работает акция
  services?: PromoServiceType[]
}

export interface PromoBonus {
  // Блюдо в подарок (null - 1+1)
  item_id?: string
  // Процент скидки; должен быть кратен 5
  discount?: number
  cashback?: number[]
}

export interface FreeDeliveryData extends BasePromoData<PromoEnum.FREE_DELIVERY> {
  min_order_price: number
  budget_limit?: number
  schedule: PromoSchedule[]
}

export interface CartDiscountData extends BasePromoData<PromoEnum.CART_DISCOUNT> {
  schedule?: PromoSchedule[]
  min_order_price: number
  discount: number
  services?: PromoServiceType[]
}
export interface PlusFirstOrdersData extends BasePromoData<PromoEnum.PLUS_FIRST_ORDERS> {
  min_order_price: number
  cashback: number[] | undefined
}

export interface DiscountData extends BasePromoData<PromoEnum.DISCOUNT> {
  discount: number
  item_ids?: string[]
  schedule: PromoSchedule[]
}

export interface PlusHappyHoursData extends BasePromoData<PromoEnum.PLUS_HAPPY_HOURS> {
  cashback: number[] | undefined
  min_order_price: number
  schedule: PromoSchedule[]
}

export interface GiftData extends BasePromoData<PromoEnum.GIFT> {
  min_order_price: number
  item_id: string
  schedule: PromoSchedule[]
}

export interface OnePlusOneData extends BasePromoData<PromoEnum.ONE_PLUS_ONE> {
  item_ids?: string[]
  schedule: PromoSchedule[]
}

export interface LoyaltyDiscountFormerData extends BasePromoData<PromoEnum.LOYALTY_DISCOUNT_FORMER> {
  place_days_from_last_order_from?: number
}

export interface LoyaltyDiscountNewData extends BasePromoData<PromoEnum.LOYALTY_DISCOUNT_NEW> {
  place_orders_counts?: number[] | undefined
}

export interface LoyaltyDiscountRecentData extends BasePromoData<PromoEnum.LOYALTY_DISCOUNT_RECENT> {
  place_days_from_last_order_till?: number
}

export interface BasePromoData<TPromoType extends PromoBaseType = PromoBaseType> {
  type: TPromoType
  place_ids: number[]
  starts_at: string
  ends_at: string
  order_indexes?: number[]
  days_from_last_order?: number
  shipping_types?: string[]
  discount?: number
  services?: PromoServiceType[]
  schedule?: Array<{
    day: 1 | 2 | 3 | 4 | 5 | 6 | 7
    from: number
    to: number
  }>
  has_consent?: boolean
}

export interface MapPromoTypeData {
  [PromoEnum.FREE_DELIVERY]: FreeDeliveryData
  [PromoEnum.PLUS_FIRST_ORDERS]: PlusFirstOrdersData
  [PromoEnum.PLUS_HAPPY_HOURS]: PlusHappyHoursData
  [PromoEnum.DISCOUNT]: DiscountData
  [PromoEnum.GIFT]: GiftData
  [PromoEnum.ONE_PLUS_ONE]: OnePlusOneData
  [PromoEnum.CART_DISCOUNT]: CartDiscountData
  [PromoEnum.LOYALTY_DISCOUNT_FORMER]: LoyaltyDiscountFormerData
  [PromoEnum.LOYALTY_DISCOUNT_NEW]: LoyaltyDiscountNewData
  [PromoEnum.LOYALTY_DISCOUNT_RECENT]: LoyaltyDiscountRecentData
}

export type Money = {
  /** example: '"123456.78"' */
  price_value: string
  currency: CurrencyType
}
