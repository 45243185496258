import {useI18n} from '@eda-restapp/i18n'
import {
  Box,
  DeliveryIcon,
  Typography,
  Clock as ClockIcon,
  ChevronRightMIcon,
  BonusIcon,
  OutsideLink,
  Clamp
} from '@eda-restapp/ui'
import cn from 'classnames'
import type {FC} from 'react'

import {formatNumber} from '@restapp/shared/utils'

import type {PlaceCardProps} from '../../PlaceCard'
import {BonusesProgressBar} from '../BonusesProgressBar/BonusesProgressBar'
import {PlaceBonuses} from '../PlaceBonuses/PlaceBonuses'

import styles from './PlaceCardDesktop.module.css'

export const PlaceCardDesktop: FC<PlaceCardProps> = ({
  placeName,
  placeAddress,
  placeDeliverySlug,
  conditionsLink,
  bonusesApplied,
  currentBalance,
  totalBalance,
  burnSoon,
  onClick,
  leftDays,
  untilDateString,
  upToOrders,
  className
}) => {
  const {t, lang, tRaw} = useI18n()

  return (
    <Box
      data-testid='place-card'
      className={cn(
        styles.root,
        {
          [styles.rootBurning]: burnSoon && !bonusesApplied,
          [styles.rootBurningLeft]: burnSoon && !bonusesApplied
        },
        className
      )}
    >
      <Box className={styles.infoBlock}>
        <Box className={styles.paddingBottom12} flexDirection='column' gap='s' style={{width: '100%'}}>
          <Box data-testid='place-info' gap='s' alignItems='center'>
            <DeliveryIcon deliverySlug={placeDeliverySlug} />
            <Box flexDirection='column'>
              <Typography variant='body2' thickness='medium'>
                {placeName}
              </Typography>
              <Typography variant='caption1'>
                <Clamp lines={1}>{placeAddress}</Clamp>
              </Typography>
            </Box>
          </Box>
          <Box justifyContent='space-between'>
            <Box data-testid='bonuses-date' gap='s' alignItems='center'>
              <Box className={styles.icon}>
                <ClockIcon className={styles.minorText} fontSize={16} />
              </Box>
              <Box>
                <Typography className={styles.minorText} variant='caption1'>
                  {t('promotion.bonuses-place-card.until-burned-down-date', 'До {date}', {
                    date: untilDateString
                  })}
                  {burnSoon && ' · '}
                </Typography>
                {burnSoon && (
                  <Typography className={styles.burningText} variant='caption1' thickness='medium'>
                    {t('promotion.bonuses-place-card.some-days-left', 'Осталось {count} день/дня/дней', {
                      one: 'Остался {count} день',
                      some: 'Осталось {count} дня',
                      many: 'Осталось {count} дней',
                      count: leftDays
                    })}
                  </Typography>
                )}
              </Box>
            </Box>
            {bonusesApplied && (
              <Box data-testid='bonuses-count' gap='s' alignItems='center'>
                <Typography variant='caption1' thickness='medium'>
                  {formatNumber(Number(currentBalance), lang, {useGrouping: true}) +
                    ' / ' +
                    formatNumber(Number(totalBalance), lang, {useGrouping: true})}
                </Typography>
                <BonusIcon fontSize={16} />
              </Box>
            )}
          </Box>
          {!bonusesApplied && (
            <OutsideLink className={cn(styles.link, styles.linkInHeader)} href={conditionsLink} target='_blank'>
              <Typography variant='caption1'>
                {t('promotion.bonuses-place-card.conditions-link', 'Условия акции')}
              </Typography>
            </OutsideLink>
          )}
        </Box>
        {!bonusesApplied && (
          <Box className={styles.bonuses} flexDirection='column' gap='s' alignItems='center' justifyContent='end'>
            <PlaceBonuses total={totalBalance} burnSoon={burnSoon} />
            <Typography data-testid='up-to-orders' className={styles.minorText} variant='caption1' lineHeight='normal'>
              {tRaw('promotion.bonuses-place-card.up-to-orders', 'Можно оплатить {orderCnt}', {
                orderCnt: (
                  <span className={styles.minorTextPre}>
                    {t('promotion.bonuses-place-card.up-to-orders-orders-cnt', 'до {count} заказов', {
                      one: 'до {count} заказа',
                      some: 'до {count} заказов',
                      many: 'до {count} заказов',
                      count: upToOrders
                    })}
                  </span>
                )
              })}
            </Typography>
          </Box>
        )}
      </Box>
      {bonusesApplied && (
        <BonusesProgressBar current={Number(currentBalance)} max={Number(totalBalance)} burnSoon={burnSoon} />
      )}
      <Box justifyContent='space-between' alignItems='center'>
        <Box className={cn(styles.button, {[styles.paddingTop16]: bonusesApplied})} onClick={onClick}>
          <Typography data-testid='place-card-action' variant='body2'>
            {bonusesApplied
              ? t('promotion.bonuses-place-card.go-to-promotion', 'К рекламной кампании')
              : t('promotion.bonuses-place-card.use-bonuses-button', 'Использовать бонусы')}
          </Typography>
          <ChevronRightMIcon fontSize='16px' />
        </Box>
        {bonusesApplied && (
          <OutsideLink className={cn(styles.link, styles.linkInFooter)} href={conditionsLink} target='_blank'>
            <Typography variant='caption1'>
              {t('promotion.bonuses-place-card.conditions-link', 'Условия акции')}
            </Typography>
          </OutsideLink>
        )}
      </Box>
    </Box>
  )
}
