import type {BonusesCampaign} from '@restapp/shared-api/types/promotion'

type GetBonusesCampaignsProps = {
  newBonusesCampaigns?: BonusesCampaign[]
  acceptedBonusesCampaigns?: BonusesCampaign[]
}

export const MAX_CAMPAIGNS_TO_DISPLAY = 3

export const getBonusesCampaigns = ({newBonusesCampaigns, acceptedBonusesCampaigns}: GetBonusesCampaignsProps) => {
  const showNewBonusesCampaigns = newBonusesCampaigns && newBonusesCampaigns.length > 0
  const manyNewBonusesCampaigns =
    newBonusesCampaigns && showNewBonusesCampaigns ? newBonusesCampaigns.length > MAX_CAMPAIGNS_TO_DISPLAY : false

  const showAcceptedBonusesCampaigns =
    acceptedBonusesCampaigns && acceptedBonusesCampaigns.length > 0 && !showNewBonusesCampaigns
  const manyAcceptedBonusesCampaigns =
    acceptedBonusesCampaigns && showAcceptedBonusesCampaigns
      ? acceptedBonusesCampaigns.length > MAX_CAMPAIGNS_TO_DISPLAY
      : false

  return {
    campaigns: showNewBonusesCampaigns ? newBonusesCampaigns : acceptedBonusesCampaigns,
    manyCampaigns: manyNewBonusesCampaigns || manyAcceptedBonusesCampaigns
  }
}
