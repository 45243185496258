import {useI18n} from '@eda-restapp/i18n'

import {useHeavyPlaces} from './useHeavyPlaces'

type PlaceCurrencyProps = {
  suspense?: boolean
}

export const usePlaceCurrency = (options?: PlaceCurrencyProps) => {
  const {t} = useI18n()
  const {getPlaceById, isLoading} = useHeavyPlaces({enabled: true, limit: 999, suspense: options?.suspense})

  return {
    isLoading,
    getPlaceCurrency: (placeId: number) =>
      getPlaceById(placeId)?.currency ?? {
        code: 'RUB',
        sign: '₽',
        template: '$VALUE$ $SIGN$$CURRENCY$',
        text: t('use-place-currency.default-currency-text', 'руб.')
      }
  }
}
