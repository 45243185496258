import {createContext, useContext} from 'react'

import type {CampaignCPA} from '../../../../../types/campaign'

type PromoDrawerContextType = {
  campaigns?: CampaignCPA[]
}

export const PromoDrawerContext = createContext<PromoDrawerContextType>({})

export const usePromoDrawerContext = () => useContext(PromoDrawerContext)
