import {QueryClientProvider} from '@tanstack/react-query'
import React from 'react'

import {ApiDepsContext} from '../contexts/ApiDepsContext'
import type {ApiDeps} from '../types'

const ApiDepsProvider: React.FC<ApiDeps & {children?: React.ReactNode}> = ({
  request,
  socketEvent$,
  queryClient,
  snackbar,
  children
}) => (
  <ApiDepsContext.Provider value={{request, socketEvent$, queryClient, snackbar}}>
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  </ApiDepsContext.Provider>
)

export default ApiDepsProvider
