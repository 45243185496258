import {useIsMobileStrict} from '@eda-restapp/ui'
import type {FC} from 'react'

import {PlaceCardDesktop} from './components/PlaceCardDesktop/PlaceCardDesktop'
import {PlaceCardMobile} from './components/PlaceCardMobile/PlaceCardMobile'

export type PlaceCardProps = {
  placeName: string
  placeAddress: string
  placeDeliverySlug: string
  burnSoon: boolean
  totalBalance: string
  currentBalance: string
  conditionsLink: string
  leftDays: number
  bonusesApplied: boolean
  untilDateString: string
  upToOrders: number
  className?: string
  onClick(): void
}
export const PlaceCard: FC<PlaceCardProps> = (props) => {
  const isMobile = useIsMobileStrict()

  return isMobile ? <PlaceCardMobile {...props} /> : <PlaceCardDesktop {...props} />
}
