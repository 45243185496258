import {useI18n} from '@eda-restapp/i18n'
import {Box, Typography, BonusIcon, ShoppingBagFillIcon, useMediaQuery} from '@eda-restapp/ui'

import styles from './WhiteCard.module.css'

export const PROMO_IMAGE_MOBILE_SIZE = '(max-width: 510px)'

export const WhiteCard = () => {
  const {t, tRaw} = useI18n()
  const isCustomMobile = useMediaQuery(PROMO_IMAGE_MOBILE_SIZE)

  return (
    <Box p='m' className={styles.root} flexDirection='column'>
      <Box>
        <Box className={styles.marketCircle} alignItems='center' justifyContent='center'>
          <ShoppingBagFillIcon className={styles.circleIcon} color='white' />
        </Box>
        <Box className={styles.bonusCircle} alignItems='center' justifyContent='center'>
          <BonusIcon color='black' className={styles.circleIcon} />
        </Box>
      </Box>
      <Typography
        variant={isCustomMobile ? 'caption1' : 'body1'}
        thickness='bold'
        lineHeight={isCustomMobile ? 'tight' : 'normal'}
        mt='m'
        className={styles.title}
      >
        {tRaw(
          'promotion.white-card.attract-new-orders-for-one-ruble-without-price',
          'Привлекайте\nновые заказы за {price}',
          {
            price: (
              <span className={styles.price}>
                {t('promotion.white-card.attract-new-orders-for-one-ruble-price', '1 ₽')}
              </span>
            )
          }
        )}
      </Typography>
      <Typography
        variant={isCustomMobile ? 'caption2' : 'caption1'}
        lineHeight={isCustomMobile ? 'tight' : 'normal'}
        mt={isCustomMobile ? 'xs' : 's'}
      >
        {t(
          'promotion.white-card.pay-for-attract-orders',
          'Платите только\nза привлеченные заказы\nи используйте бонусы\nдля оплаты продвижения'
        )}
      </Typography>
      <Box className={styles.bottomActions} justifyContent='space-between' alignItems='center'>
        <Box className={styles.applyButton} justifyContent='center'>
          <Typography variant='caption1' lineHeight='normal' thickness='medium' className={styles.applyButtonText}>
            {t('promotion.white-card.apply', 'Применить')}
          </Typography>
        </Box>
        <Box alignItems='center'>
          <Typography variant={isCustomMobile ? 'caption2' : 'caption1'} lineHeight='normal' thickness='medium' mr='xs'>
            1000
          </Typography>
          <BonusIcon color='black' className={styles.icon} />
        </Box>
      </Box>
    </Box>
  )
}
