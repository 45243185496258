import {useExp3} from '@eda-restapp/configs'
import {getDirectToken} from '@eda-restapp/microfrontend'
import {usePermission} from '@eda-restapp/permissions'
import {useQuery} from '@tanstack/react-query'
import {useSelector} from 'react-redux'

import {selectOauthToken} from '@restapp/core-auth'

import useCampaignGroups from './useCampaignGroups'

export const useSuggestPromotion = () => {
  const promotionSuggestConfig = useExp3('restapp_promotion_suggest')
  const directTokenQuery = useQuery(['directToken'], getDirectToken)
  const shouldSuggest = usePermission('permission.promotion.suggest')
  const oauthToken = useSelector(selectOauthToken)
  const campaignGroups = useCampaignGroups()

  const campaignsAmount = campaignGroups.groups?.length ?? 0
  const isEmptyCampaigns = campaignsAmount === 0

  const directToken = directTokenQuery.data
  const isLoadingDirectToken = directTokenQuery.isLoading && directTokenQuery.isFetching

  return {
    isNeedLoggedInSuggestPromotion:
      directToken === null && promotionSuggestConfig.enabled && shouldSuggest && !!oauthToken && isEmptyCampaigns,
    isLoggedInSuggestPromotion:
      !!directToken && promotionSuggestConfig.enabled && shouldSuggest && !!oauthToken && isEmptyCampaigns,
    isSuggestFetched: campaignGroups.isFetched && !isLoadingDirectToken
  }
}
