import {useI18n} from '@eda-restapp/i18n'
import {Box, Drawer, Button} from '@eda-restapp/ui'
import {type FC} from 'react'

import type {BonusesCampaign} from '@restapp/shared-api/types/promotion'

import type {BonusesTab} from './BonusesListTab.container'
import {BonusesListContainer} from './components/BonusesList/BonusesList.container'
import {BonusesLoader} from './components/BonusesLoader/BonusesLoader'
import {HeadSection} from './components/HeadSection/HeadSection'

type BonusesListTabProps = {
  total?: string
  onToggleDrawerTab: () => void
  onClose: () => void
  isNewBonusesListTab: boolean
  tab: BonusesTab
  onToggleBonusesTab: () => void
  onNavigateToPromotion: () => void
  onNavigateToPromotionDrawer: () => void
  onLoadMore: () => void
  canLoadMore: boolean
  newBonusesCampaigns?: BonusesCampaign[]
  acceptedBonusesCampaigns?: BonusesCampaign[]
  isBonusesFetched: boolean
  isBonusesLoading: boolean
}

export const BonusesListTab: FC<BonusesListTabProps> = ({
  total,
  onToggleDrawerTab,
  onClose,
  isNewBonusesListTab,
  newBonusesCampaigns,
  acceptedBonusesCampaigns,
  tab,
  onToggleBonusesTab,
  onNavigateToPromotion,
  onNavigateToPromotionDrawer,
  isBonusesFetched,
  isBonusesLoading,
  onLoadMore,
  canLoadMore
}) => {
  const {t} = useI18n()

  return (
    <Drawer.Container floatClose onClose={onClose}>
      <Drawer.Content>
        <Box flexDirection='column' flexGrow={1}>
          <HeadSection total={total} onToggleDrawerTab={onToggleDrawerTab} />
          <BonusesListContainer
            onToggleBonusesTab={onToggleBonusesTab}
            tab={tab}
            newBonusesCampaigns={newBonusesCampaigns}
            acceptedBonusesCampaigns={acceptedBonusesCampaigns}
            isBonusesFetched={isBonusesFetched}
            onToggleDrawerTab={onToggleDrawerTab}
          />

          {isBonusesFetched && (
            <BonusesLoader onLoadMore={onLoadMore} canLoadMore={canLoadMore} isBonusesLoading={isBonusesLoading} />
          )}
        </Box>
      </Drawer.Content>

      {isBonusesFetched && (
        <Drawer.Footer>
          <Box flexDirection='column' gap='s'>
            <Button.Text
              data-testid='to-promotion-button'
              onClick={onNavigateToPromotion}
              key={isNewBonusesListTab ? 'secondary' : 'dark'}
              variant={isNewBonusesListTab ? 'secondary' : 'dark'}
            >
              {t('promotion.promo-drawer.to-promotion', 'К Продвижению')}
            </Button.Text>

            {isNewBonusesListTab && (
              <Button.Text data-testid='use-all-bonuses' variant='dark' onClick={onNavigateToPromotionDrawer}>
                {t('promotion.promo-drawer.use-all-bonuses', 'Использовать все бонусы')}
              </Button.Text>
            )}
          </Box>
        </Drawer.Footer>
      )}
    </Drawer.Container>
  )
}
