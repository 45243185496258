import {useExp3} from '@eda-restapp/configs'
import type {FC} from 'react'
import {useLocation} from 'react-router-dom'

import {DeliveryFullscreen} from './DeliveryFullscreen'

export type DeliveryFullscreenContainerProps = {}

export const DeliveryFullscreenContainer: FC<DeliveryFullscreenContainerProps> = () => {
  const location = useLocation()
  const mpdFullscreenConfig = useExp3('restapp_mpd_fullscreen')

  const isOpened = location.hash.includes('mpd-fullscreen')

  const handleClose = () => {
    const params = new URLSearchParams(location.hash.replace('#', ''))
    params.delete('mpd-fullscreen')
    window.location.hash = `#${params.toString()}`
  }

  return (
    <DeliveryFullscreen
      isOpened={isOpened}
      aboutImage={mpdFullscreenConfig.links.aboutImage}
      questionsLink={mpdFullscreenConfig.links.questionsLink}
      footerButton={mpdFullscreenConfig.links.footerButton}
      onClose={handleClose}
    />
  )
}
