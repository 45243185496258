import {Typography} from '@eda-restapp/ui'
import type {FC, ReactNode} from 'react'
import React from 'react'

import styles from './DeliveryFullscreenBadge.module.css'

export type DeliveryFullscreenBadgeProps = {
  adornment: ReactNode
  text: string
}

export const DeliveryFullscreenBadge: FC<DeliveryFullscreenBadgeProps> = ({adornment, text}) => {
  return (
    <div className={styles.container}>
      {!!adornment && adornment}

      <Typography variant='body2' color='main' lineHeight='tight'>
        {text}
      </Typography>
    </div>
  )
}
