import {ChevronDownMIcon, ChevronUpMIcon, Box} from '@eda-restapp/ui'
import cn from 'classnames'
import type {ReactNode, FC} from 'react'
import React, {useState, useRef} from 'react'

import useHasScroll from '@restapp/core-legacy/hooks/useHasScroll'

import styles from './PlacesListContainer.module.css'

interface PlacesListContainerProps {
  className?: string
  classNameList?: string
  type?: 'list' | 'dropdown'
  headerTitle: string | ReactNode
  children: ReactNode
  startAdornment?: ReactNode
  isDefaultExpanded?: boolean
}

export const PlacesListContainer: FC<PlacesListContainerProps> = ({
  className,
  type = 'dropdown',
  isDefaultExpanded,
  headerTitle,
  classNameList,
  startAdornment,
  children
}) => {
  const listRef = useRef(null)
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded ?? true)
  const {hasVerticalScroll} = useHasScroll(listRef)

  const handleClick = () => {
    setIsExpanded((prev) => !prev)
  }

  return (
    <Box flexDirection='column' flexGrow={1} data-testid='places-list'>
      {type === 'dropdown' && (
        <Box
          className={cn(styles.select, isExpanded && styles.openSelect, className)}
          p='m'
          onClick={handleClick}
          justifyContent='space-between'
          alignItems='center'
        >
          {headerTitle}

          {isExpanded ? <ChevronUpMIcon /> : <ChevronDownMIcon />}
        </Box>
      )}

      <Box px='m' className={styles.dividerWrapper}>
        {type === 'dropdown' && <Box className={cn(isExpanded && styles.selectDivider)} />}
      </Box>

      {isExpanded && (
        <Box className={cn(hasVerticalScroll && styles.listWrapperOverflowed)}>
          <Box
            flexDirection='column'
            px='m'
            py='s'
            className={cn(styles.list, isExpanded && type !== 'list' && styles.selectList, classNameList)}
            ref={listRef}
          >
            {startAdornment}
            <Box flexDirection='column' className={cn(styles.placesList)} flexGrow={1}>
              {children}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
