import {useInfiniteLoader} from '@eda-restapp/hooks'
import {Spinner} from '@eda-restapp/ui'
import type {FC} from 'react'

type BonusesLoaderProps = {
  isBonusesLoading: boolean
  canLoadMore: boolean
  onLoadMore: () => void
}

export const BonusesLoader: FC<BonusesLoaderProps> = ({isBonusesLoading, canLoadMore, onLoadMore}) => {
  const {loaderRef} = useInfiniteLoader<HTMLDivElement>({
    loadMore: onLoadMore,
    canLoadMore,
    rootMargin: '0px'
  })

  return <div ref={loaderRef}>{isBonusesLoading && <Spinner />}</div>
}
