import {useApiMutation} from '@restapp/core-api'
import {notificationsChannel, useChannelSubscription} from '@eda-restapp/microfrontend'
import {useExp3} from '@eda-restapp/configs'
import {useSelector} from 'react-redux'
import {selectDeviceInfo} from '@restapp/bootstrap/native/selectors'

const AcknowledgePushNotification = () => {
  const webPushConfig = useExp3('restapp_webpush')
  const deviceInfo = useSelector(selectDeviceInfo)

  const acknowledgeMutation = useApiMutation({
    url: webPushConfig.newApiEnabled
      ? '/4.0/restapp-front/communications/v1/notify/acknowledge'
      : '/4.0/restapp-front/notify/v1/acknowledge',
    method: 'POST'
  })

  useChannelSubscription(notificationsChannel, (notification) => {
    if ((notification.meta.source === 'push' || notification.meta.source === 'web-push') && notification.meta.id) {
      acknowledgeMutation.mutate({
        body: {
          notification_id: notification.meta.id,
          device_id: notification.meta.source === 'push' ? deviceInfo?.deviceId : 'web_device_id',
          status: 'shown', // не умеем отправлять ничего больше
          ...(webPushConfig.newApiEnabled
            ? {client_dttm: new Date().toISOString()}
            : {received_at: new Date().toISOString()})
        }
      })
    }
  })

  return null
}

export default AcknowledgePushNotification
