import type {SnackbarDescription} from '@eda-restapp/snackbars'
import cn from 'classnames'
import {Snackbar} from '@eda-restapp/ui'
import React, {createRef, useLayoutEffect, useMemo, useRef, useState} from 'react'
import styles from './Snackbars.module.css'
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import {SnackbarsListControls} from './components/SnackbarsListControls/SnackbarsListControls'
import {SnackbarsClearButton} from './components/SnackbarsClearButton/SnackbarsClearButton'
import type {CSSProperties, ReactNode} from 'react'
import * as icons from '@eda-restapp/ui/src/icons'

export type SnackbarsProps = {
  snackbars: SnackbarDescription[]
  onClose: (snackbar: Pick<SnackbarDescription, 'id'>) => void
  onClick: (snackbar: Pick<SnackbarDescription, 'id'>) => void
  onCloseAll: () => void
  defaultCollapsed?: boolean
  transitionMs?: number
}

export const Snackbars: React.FC<SnackbarsProps> = ({
  snackbars,
  onClose,
  onClick,
  onCloseAll,
  defaultCollapsed = true,
  transitionMs = 300
}) => {
  const reversedSnackbarsWithRefs = useMemo(
    () =>
      snackbars
        .slice()
        .reverse()
        .map((snackbar) => {
          return {nodeRef: createRef<HTMLDivElement>(), ...snackbar}
        }),
    [snackbars]
  )
  const [currentSnackbars, setCurrentSnackbars] = useState(reversedSnackbarsWithRefs)
  const [isEnterTransitionRunning, setIsEnterTransitionRunning] = useState(false)

  useLayoutEffect(() => {
    // Prevents snackbar jumping when they appearing fast
    if (!isEnterTransitionRunning && currentSnackbars !== reversedSnackbarsWithRefs) {
      setCurrentSnackbars(reversedSnackbarsWithRefs)
    }
  }, [isEnterTransitionRunning, currentSnackbars, reversedSnackbarsWithRefs])

  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed)
  const toggleCollapse = () => setIsCollapsed((v) => !v)

  const isEmpty = currentSnackbars.length === 0
  useLayoutEffect(() => {
    // помогает избежать ситуации когда состояние свернутости "залипает" после закрытия всех снекбаров
    if (isEmpty === true && isCollapsed === false) {
      const timeoutId = setTimeout(() => setIsCollapsed(true), transitionMs)
      return () => clearTimeout(timeoutId)
    }
  }, [isEmpty, isCollapsed, transitionMs])

  const listRef = useRef<HTMLDivElement>(null)

  const handleClick = (snackbar: SnackbarDescription) => {
    if (isCollapsed && currentSnackbars.length > 1) {
      toggleCollapse()
      return
    }

    if (snackbar.clickable) {
      onClick(snackbar)
    }
  }

  const showClearAllButton = isCollapsed && currentSnackbars.length > 1

  const getSnackbarIcon = (snackbar: SnackbarDescription) => {
    if (snackbar.type !== 'custom') {
      return undefined
    }

    const SnackbarIcon = icons[snackbar.icon.name] as (props: any, deprecatedLegacyContext?: any) => ReactNode

    return <SnackbarIcon fontSize={24} {...(snackbar.icon.props ?? {})} />
  }

  return (
    <div
      className={cn(styles.root, {[styles.collapsed]: isCollapsed, [styles.rootEmpty]: snackbars.length === 0})}
      style={
        {
          '--snackbarsTransitionTime': `${transitionMs}ms`
        } as CSSProperties
      }
    >
      <SnackbarsListControls
        className={styles.listControls}
        visible={!isCollapsed && snackbars.length > 0}
        onCollapse={toggleCollapse}
        onClearAll={onCloseAll}
        transitionMs={transitionMs}
      />
      <CSSTransition
        nodeRef={listRef}
        in={isCollapsed}
        timeout={transitionMs}
        classNames={{
          enter: styles.collapsedEnter,
          enterActive: styles.collapsedEnterActive,
          enterDone: styles.collapsedEnterDone,
          exit: styles.collapsedExit,
          exitActive: styles.collapsedExitActive
        }}
      >
        <div ref={listRef} className={cn(styles.list, styles.disablePointerEvents)}>
          <TransitionGroup component={null}>
            {currentSnackbars.map((snackbar, i) => {
              const isFirstSnackbar = i === 0

              return (
                <CSSTransition
                  key={snackbar.id}
                  nodeRef={snackbar.nodeRef}
                  timeout={transitionMs}
                  classNames={{...styles}}
                  onEnter={isFirstSnackbar ? () => setIsEnterTransitionRunning(true) : undefined}
                  onEntered={isFirstSnackbar ? () => setIsEnterTransitionRunning(false) : undefined}
                >
                  <Snackbar
                    ref={snackbar.nodeRef}
                    id={snackbar.id}
                    key={snackbar.id}
                    hasAction={snackbar.clickable}
                    className={cn(styles.snackbar, styles.enablePointerEvents)}
                    message={snackbar.text}
                    type={snackbar.type}
                    icon={getSnackbarIcon(snackbar)}
                    errorCode={snackbar.errorCode}
                    background={snackbar?.background}
                    textColor={snackbar?.textColor}
                    onClose={showClearAllButton ? undefined : () => onClose(snackbar)}
                    onClick={() => handleClick(snackbar)}
                  />
                </CSSTransition>
              )
            })}
          </TransitionGroup>

          {showClearAllButton && (
            <SnackbarsClearButton className={cn(styles.clearButton, styles.enablePointerEvents)} onClick={onCloseAll} />
          )}
        </div>
      </CSSTransition>
    </div>
  )
}
