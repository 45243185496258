import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {promotionChannel, useChannelSubscription} from '@eda-restapp/microfrontend'
import {Snackbar} from '@eda-restapp/snackbars'
import {useEffect} from 'react'

import {useModal, useDirect, useBalance, usePromocodeType} from '@restapp/promotion/hooks'

import DirectPaymentPopup from './DirectPaymentPopup'

/** Контролирует открытие/закрытие модалки оплаты.
 * Слушает событие на открытие модалки через broadcastChannel.
 * Необходим, чтобы iframe директа открывался только на уровне Core, иначе (если открывать внутри МФ) навигация внутри iframe директа не работает.
 * SEE: https://st.yandex-team.ru/EDARESTAPP-10473
 */
export const DirectPaymentPopupStandalone = () => {
  const {t} = useI18n()

  const promotionConfig = useExp3('restapp_promotion')
  const directTopUpDisabled = promotionConfig.cpcDropProperties.directTopUpDisabled
  const {isLoggedDirect, directUsername, directToken, invalidateDirect} = useDirect()

  const {isOpen: isPaymentModalShown, open, close} = useModal({modalName: 'payment'})
  const promocodeType = usePromocodeType()

  useChannelSubscription(promotionChannel, (msg) => {
    if (msg.type === 'show-direct-payment-modal') {
      if (msg.payload.open) {
        invalidateDirect()
        open()
        !!promotionConfig.balancePromocodeType && promocodeType.set(promotionConfig.balancePromocodeType)
      } else {
        close()
      }
    }
  })

  const {updateBalance, isBalanceUpdating} = useBalance({
    directToken,
    onBalanceUpdated: () => {
      Snackbar.success({
        text: t('promotion.balance-dropdown.balans-uspeshno-popolnen', 'Баланс успешно пополнен')
      })
    }
  })

  const closeDirectModal = (success: boolean) => {
    close()

    if (success) {
      updateBalance()
      void promotionChannel.broadcast({type: 'update-balance', sender: 'root'})
    }
  }

  useEffect(() => {
    if (isBalanceUpdating) {
      Snackbar.show({
        text: t('balance-dropdown.schyot-popolnyaetsya', 'Счёт пополняется...'),
        type: 'info',
        background: 'white'
      })
    }
  }, [isBalanceUpdating, t])

  if (!(isPaymentModalShown && !directTopUpDisabled && !!directUsername && isLoggedDirect)) {
    return null
  }
  return <DirectPaymentPopup close={closeDirectModal} directUsername={directUsername} />
}
