import {eventLogger} from '@eda-restapp/logger'
import React, {useMemo} from 'react'
import type {CSSProperties, FC, ReactNode} from 'react'

import {useApiQuery} from '@restapp/core-api'

import {SupportTree} from './SupportTree'
import type {SupportTreeWidget} from './SupportTree.types'

export type SupportTreeContainerProps = {
  path: string[]
  onChange: (id: string) => void

  children: (widgets?: SupportTreeWidget[]) => ReactNode

  className?: string
  style?: CSSProperties
}

export const SupportTreeContainer: FC<SupportTreeContainerProps> = ({path, onChange, children, className, style}) => {
  const treeQuery = useApiQuery({
    url: '/4.0/restapp-front/support_chat/v2/topic_tree',
    method: 'GET',
    suspense: true,
    select: ({tree}) => {
      return {
        id: 'initial_stub',
        slug_id: 'initial_stub',
        title: 'initial_stub',
        is_visible: true,
        widgets: [],
        tree_elements: tree
      } as (typeof tree)[0]
    }
  })

  const onTopicClick = (id: string) => {
    eventLogger({
      name: 'support_tree:click_on_topic',
      value: id,
      additional: {
        path
      }
    })

    onChange(id)
  }

  const tree = useMemo(() => {
    if (!treeQuery.data) {
      return
    }

    let current = treeQuery.data

    for (const id of path) {
      const next = current.tree_elements?.find((element) => element.id === id)

      if (!next) {
        throw new Error(`Tree element not found [id=${id}]`)
      }

      current = next
    }

    return {...current, tree_elements: current.tree_elements?.filter((elem) => elem.is_visible !== false)}
  }, [path, treeQuery.data])

  if (!tree) {
    return null
  }

  if (tree.widgets?.length) {
    return children(tree.widgets)
  }

  if (tree.tree_elements?.length) {
    return (
      <SupportTree elements={tree.tree_elements} onElementClick={onTopicClick} className={className} style={style} />
    )
  }

  throw new Error(`Current tree branch doesn't have 'widgets' or 'tree_elements' [path=${path.join(', ')}]`)
}
