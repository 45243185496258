import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'

import {PLACE_STATUS} from '@restapp/main-places/types/status'
import type {FullPlaceType, OldFullRestaurant} from '@restapp/shared-api'
import {getAutostopFinishTime, getPlaceEnabledHumanDate, getOldPlaceStatus} from '@restapp/shared-places'
import {usePlaceStatus} from '@restapp/shared-places/hooks/usePlaceStatus'

export const usePlaceStatusWithErrorLabel = (place?: FullPlaceType | OldFullRestaurant) => {
  const placeConfig = useExp3('restapp_place')
  const placeStatus = usePlaceStatus(place as FullPlaceType)
  const {t} = useI18n()

  if (!place) {
    return
  }

  const resultPlaceStatus = placeConfig.newRestaurantSearchHandle
    ? placeStatus
    : getOldPlaceStatus(place as OldFullRestaurant)

  switch (resultPlaceStatus) {
    case PLACE_STATUS.DISABLED_BY_AUTOSTOP:
      return {
        text:
          getAutostopFinishTime(place.disable_details?.available_at) || t('main-places.utils.otklyuchen', 'Отключён'),
        error: true
      }
    default:
      return {
        text:
          (place.disable_details?.available_at && getPlaceEnabledHumanDate(place.disable_details.available_at)) ||
          t('main-places.utils.otklyuchen', 'Отключён'),
        error: true
      }
  }
}
