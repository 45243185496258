import {useExp3} from '@eda-restapp/configs'

import {useApiQuery} from '@restapp/core-api'
import {usePlaces} from '@restapp/core-places'

type UseBonusesBalanceProps = {
  enabled?: boolean
}

export const useBonusesBalance = ({enabled = true}: UseBonusesBalanceProps = {}) => {
  const {placesIds} = usePlaces()
  const promotionConfig = useExp3('restapp_promotion')

  const bonusesBalanceQuery = useApiQuery({
    url: '/4.0/restapp-front/place-bonus-points/v1/partners/balance',
    method: 'POST',
    body: {
      place_ids: placesIds.map((id) => String(id)),
      features: ['cpa_ad_conversion']
    },
    enabled: enabled && !!placesIds && placesIds.length > 0 && promotionConfig.cpaBonuses.isStartByBonusesAvailable
  })

  return {
    balance: bonusesBalanceQuery.data?.balance,
    total: bonusesBalanceQuery.data?.total,
    balanceToBurn: bonusesBalanceQuery.data?.balance_to_burn
  }
}
