import {History, Orders, useIsDesktopStrict} from '@eda-restapp/ui'
import type {ReactNode} from 'react'
import React, {memo} from 'react'
import {useSelector} from 'react-redux'
import useLocalStorageState from 'use-local-storage-state'

import {useUserInfo} from '@restapp/core-auth'
import {CriticalMessageWidgetContainer} from '@restapp/main-communications'
import type {NavigationBadge, NavigationElement} from '@restapp/shared-api'
import type {AppState} from '@restapp/store'

import type {SidebarElement, SidebarLink, SidebarIFrame} from '../../components/Sidebar'
import CustomSidebarBadge from '../../components/Sidebar/CustomSidebarBadge'
import Sidebar from '../../components/Sidebar/Sidebar'
import {SidebarAccountElementContainer} from '../../components/Sidebar/SidebarAccountElement'
import type {BadgesType} from '../../components/Sidebar/SidebarBadge'
import SidebarBadge from '../../components/Sidebar/SidebarBadge'
import {HAS_USER_SEEN_ACCOUNT_POPOVER_KEY, SEEN_BADGES_KEY} from '../../constants'
import {useSidebar} from '../../hooks'

type SidebarMap = Partial<
  Record<
    string,
    {
      path?: string
      icon?: ReactNode
    }
  >
>

export interface SidebarContainerProps {
  sidebarMap: SidebarMap
  logoPath: string
  mainNavElements: NavigationElement[]
  footerNavElements: NavigationElement[]
}

const findIconByName = (name: string) => {
  switch (name) {
    case 'Orders':
      return <Orders />
    case 'History':
      return <History />
    default:
      // eslint-disable-next-line no-console
      console.warn(`Icon "${name}" not found`)
      return
  }
}

const selectNativeAppVersion = (state: AppState) => state.native.deviceInfo?.appVersion

const SidebarContainer: React.FC<SidebarContainerProps> = memo(
  ({sidebarMap, logoPath, mainNavElements, footerNavElements}) => {
    const {setVisibility, isVisible} = useSidebar()
    const nativeAppVersion = useSelector(selectNativeAppVersion)

    const userInfo = useUserInfo()

    const [hasUserSeenAccountPopover] = useLocalStorageState(HAS_USER_SEEN_ACCOUNT_POPOVER_KEY, {
      defaultValue: false
    })
    const [seenBadges] = useLocalStorageState<string[]>(SEEN_BADGES_KEY, {defaultValue: []})

    const isDesktop = useIsDesktopStrict()

    const renderBadge = (badge: NavigationBadge) => {
      if (badge.id && badge.attributes?.hideOnFirstView) {
        if (seenBadges.includes(badge.id)) {
          return null
        }

        return <SidebarBadge type={badge.type as BadgesType} />
      }

      if (badge.id) {
        return <CustomSidebarBadge badgeId={badge.id} />
      }

      return <SidebarBadge type={badge.type as BadgesType} />
    }

    const transformNavElementToSidebarElement = (navElement: NavigationElement): SidebarElement => {
      if (navElement.type === 'separator') {
        return {type: 'separator', label: navElement.label}
      }

      if (navElement.children) {
        return {
          type: 'group',
          label: navElement.label,
          color: navElement.attributes?.color,
          icon: sidebarMap[navElement.id]?.icon,
          defaultExpanded: navElement.expanded,
          badge: navElement.badge && renderBadge(navElement.badge),
          badgeSettings: {
            hideWhenExpanded: navElement.badge?.attributes?.hideWhenExpanded
          },
          children: navElement.children
            ?.map(transformNavElementToSidebarElement)
            .filter((el): el is SidebarLink | SidebarIFrame => el.type === 'iframe' || el.type === 'link')
        }
      }

      if (navElement.type === 'link' && navElement.link) {
        return {
          type: 'link',
          name: navElement.label,
          color: navElement.attributes?.color,
          path: navElement.link.path,
          icon: sidebarMap[navElement.id]?.icon,
          visible: true,
          badge: navElement.badge && renderBadge(navElement.badge),
          external: navElement.link.external,
          target: navElement.link.target
        }
      }

      if (navElement.type === 'iframe' && navElement.link && navElement.id) {
        return {
          type: 'iframe',
          name: navElement.label,
          color: navElement.attributes?.color,
          path: `/${navElement.id}`,
          icon: navElement?.icon?.name && findIconByName(navElement.icon.name),
          visible: true,
          badge: navElement.badge && renderBadge(navElement.badge),
          external: navElement.link.external
        }
      }

      return {
        type: 'link',
        name: navElement.label,
        color: navElement.attributes?.color,
        path: sidebarMap[navElement.id]?.path ?? '/404',
        icon: sidebarMap[navElement.id]?.icon,
        visible: sidebarMap[navElement.id]?.path != null,
        badge: navElement.badge && renderBadge(navElement.badge)
      }
    }

    const closeIfSmallScreen = () => {
      if (!isDesktop) {
        setVisibility(false)
      }
    }

    const openSidebarInPassportAccount = userInfo && userInfo.type === 'passport' && !hasUserSeenAccountPopover

    return (
      <Sidebar
        logoPath={logoPath}
        setVisibility={setVisibility}
        isVisible={openSidebarInPassportAccount || isVisible}
        mainElements={mainNavElements.map(transformNavElementToSidebarElement)}
        footerElements={footerNavElements.map(transformNavElementToSidebarElement)}
        Widget={<CriticalMessageWidgetContainer />}
        webVersion={VERSION}
        appVersion={nativeAppVersion}
        onNavigationPerformed={closeIfSmallScreen}
        renderAccount={(mini) => (
          <SidebarAccountElementContainer mini={mini} onNavigationPerformed={closeIfSmallScreen} />
        )}
      />
    )
  }
)
SidebarContainer.displayName = 'SidebarContainer'

export default SidebarContainer
