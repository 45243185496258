import {useExp3} from '@eda-restapp/configs'
import {useSelector} from 'react-redux'

import {InfiniteQueryAdapters, useApiInfiniteQuery} from '@restapp/core-api/hooks'
import {usePlaces} from '@restapp/core-places/hooks/usePlaces'
import {chatsSlice} from '@restapp/main-chats/slice/chatsSlice'
import type {Api, VendorApi} from '@restapp/shared-api'

export const mapOldChatsToV2 = (
  oldChat: Api['/4.0/restapp-front/support_chat/v1/chat/list']['GET']['response']['chats'][number]
): VendorApi['/4.0/restapp-front/support_chat/v2/chat/list']['POST']['response']['chats'][number] => {
  return {
    ...oldChat,
    updated_at: oldChat.last_message_created_at || oldChat.created_at,
    author_email: oldChat.author,
    order_nr: oldChat.order_id,
    new_messages_counter: oldChat.new_message_count || undefined
  }
}

export const useInfinityChatsListQuery = () => {
  const supportMultiChatConfig = useExp3('restapp_support_multi_chat')

  const chatsFilters = useSelector(chatsSlice.selectors.selectFilters)
  const topicFilterAllOption = chatsFilters.topic.slug === 'all'
  // почему сравниваем slug и title см. convertResponse в useSupportChatTopics.ts
  const topicFilterTitleOnly = topicFilterAllOption || chatsFilters.topic.slug === chatsFilters.topic.title

  const {selectedPlaceIds, placesIds} = usePlaces({suspense: true})
  const isAllPlacesSelected = selectedPlaceIds.length === placesIds.length

  const chatsListQuery = useApiInfiniteQuery(
    {
      url: '/4.0/restapp-front/support_chat/v2/chat/list',
      method: 'POST',
      params: {},
      body: {
        filters: {
          place_ids: isAllPlacesSelected ? undefined : selectedPlaceIds.map(String),
          status: chatsFilters.status === 'all' ? undefined : chatsFilters.status,
          author_partner_uuid: chatsFilters.author === 'all' ? undefined : chatsFilters.author,
          sorting_order: chatsFilters.sortOrder,
          topic: topicFilterAllOption ? undefined : chatsFilters.topic.title,
          topic_slug: topicFilterTitleOnly ? undefined : chatsFilters.topic.slug,
          newer_than: chatsFilters.dateFrom?.toISOString(),
          older_than: chatsFilters.dateTo?.toISOString()
        }
      }
    },
    {
      suspense: true,
      staleTime: 30_000,
      refetchOnMount: 'always',
      adapter: InfiniteQueryAdapters.CHATS_LIST,
      enabled: supportMultiChatConfig.enableUnifiedChatsListEndpoint
    }
  )

  const oldChatsListQuery = useApiInfiniteQuery(
    {
      url: '/4.0/restapp-front/support_chat/v1/chat/list',
      method: 'GET',
      params: {
        place_ids: isAllPlacesSelected ? undefined : selectedPlaceIds.map(String),
        status: chatsFilters.status === 'all' ? undefined : chatsFilters.status,
        author: chatsFilters.author === 'all' ? undefined : chatsFilters.author,
        sorting_order: chatsFilters.sortOrder,
        topic: topicFilterAllOption ? undefined : chatsFilters.topic.title,
        newer_than: chatsFilters.dateFrom?.toISOString(),
        older_than: chatsFilters.dateTo?.toISOString(),
        offset: 0,
        limit: 30
      }
    },
    {
      suspense: true,
      adapter: InfiniteQueryAdapters.OLD_CHATS_LIST,
      staleTime: 1000 * 60 * 3,
      enabled: !supportMultiChatConfig.enableUnifiedChatsListEndpoint,
      select: (data) => {
        return {
          pageParams: data.pageParams,
          pages: data.pages.map((page) => ({chats: page.chats.map(mapOldChatsToV2)}))
        }
      }
    }
  )

  return supportMultiChatConfig.enableUnifiedChatsListEndpoint ? chatsListQuery : oldChatsListQuery
}
