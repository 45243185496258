import {Box, Typography} from '@eda-restapp/ui'
import cn from 'classnames'
import type {FC, ReactNode} from 'react'

import styles from './StepCard.module.css'

type StepCardProps = {
  className: string
  title: string
  subtitle?: string
  icon: ReactNode
  actions?: ReactNode
  subtitleClassName?: string
  actionsClassName?: string
  images?: ReactNode
}

export const StepCard: FC<StepCardProps> = ({
  className,
  title,
  subtitle,
  icon,
  actions,
  actionsClassName,
  subtitleClassName,
  images
}) => {
  return (
    <Box p='m' className={cn(styles.root, className)} flexDirection='column' justifyContent='center'>
      {icon}

      <Box
        flexDirection='column'
        className={cn(styles.texts, !!actions && styles.textsWithoutPadding)}
        justifyContent='center'
      >
        <Typography variant='body2' color='invert' thickness='medium' lineHeight='normal' className={styles.titleText}>
          {title}
        </Typography>

        {!!subtitle && (
          <Typography
            variant='body2'
            color='invert'
            lineHeight='loose'
            mt='s'
            className={cn(styles.subtitleText, subtitleClassName)}
          >
            {subtitle}
          </Typography>
        )}

        {!!actions && (
          <Box flexDirection='column' mt='m' className={actionsClassName}>
            {actions}
          </Box>
        )}
      </Box>

      {images}
    </Box>
  )
}
