import {makeStyles} from 'tss-react/mui'

export const useNoChatsStyles = makeStyles()({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    maxWidth: 436,
    margin: '0 auto'
  },
  iconWrapper: {
    width: 72,
    height: 72,
    borderRadius: '50%',
    background: '#EDF0F1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '23px',
    marginBottom: 16
  },
  footer: {
    display: 'fixed',
    bottom: 0,
    height: 60
  }
})
