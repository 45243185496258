import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {useState, useMemo} from 'react'

import {useApiMutation} from '@restapp/core-api'
import {useFileUpload} from '@restapp/shared-ui'

import type {BannerSetupUIPropsType} from '../components/BannerSetupUI'
import {RESTRICTIONS, EXACT_RESTRICTIONS} from '../constants/bannerConstructor'
import type {CampaignCPM} from '../types/campaign'

import useConstructor from './useConstructor'

/**
 * Генерирует пропсы для секция Настройка баннера (BannerSetupUI)
 */
const useBannerSetup = ({
  campaign,
  placeName
}: {
  campaign?: CampaignCPM
  placeName?: string
}): BannerSetupUIPropsType => {
  const [infoOpen, setInfoOpen] = useState(false)
  const [text, setText] = useState(campaign?.text ?? '')
  const imageUrl = campaign?.image
  const {t} = useI18n()

  const bannerMutation = useApiMutation({
    url: '/4.0/restapp-front/marketing/v1/ad/cpm/upload_banner',
    method: 'POST'
  })

  const promotionConfig = useExp3('restapp_promotion')

  const {
    fileAsBase64: image,
    props: fileUploadProps,
    dirty: fileDirty
  } = useFileUpload({
    restrictions: RESTRICTIONS,
    forceReplaceText: !!imageUrl,
    forceCancelOnChange: campaign?.image
  })

  const textConfigs = useMemo(
    () => [
      {...promotionConfig.banner_text_configs.slogan, text},
      {
        ...promotionConfig.banner_text_configs.rest,
        text: placeName ? placeName : t('promotion.use-banner-setup.nazvalie-restorana', 'Название ресторана')
      },
      {...promotionConfig.banner_text_configs.ads, text: t('promotion.use-banner-setup.reklama', 'РЕКЛАМА')}
    ],
    [text, placeName, promotionConfig.banner_text_configs, t]
  )

  const canvasProps = useConstructor({
    imageUrl: image ?? campaign?.source_image,
    width: EXACT_RESTRICTIONS.width,
    height: EXACT_RESTRICTIONS.height,
    textConfigs
  })

  const cpmAdvertId = campaign?.cpm_advert_id

  const canvas = canvasProps.canvas

  const saveBannerImage =
    canvas && cpmAdvertId
      ? () =>
          bannerMutation.mutateAsync({
            body: {
              campaign_id: cpmAdvertId,
              image: canvasProps.stringify(canvas),
              source_image: image,
              text
            }
          })
      : undefined

  const dirty = fileDirty || text !== campaign?.text
  const isTextValid = text !== ''

  return {
    dirty,
    valid: isTextValid,
    textError: isTextValid
      ? ''
      : t(
          'promotion.promotion-banner-page.neobkhodimo-ukazat-tekst-dlya-banner',
          'Необходимо указать текст для баннера'
        ),
    bannerMutation,
    saveBannerImage,
    text,
    setText,
    imageUrl,
    sourceImageUrl: campaign?.source_image,
    image,
    fileUploadProps,
    bannerTextMaxLength: promotionConfig.banner_text_max_length,
    enableBannerPromotion: false,
    moderationStatus: campaign?.moderation_status,
    moderationReason: campaign?.moderation_rejection_reason,
    helpLinkBannerRequirements: promotionConfig.banner_link_help_banner_requirements,
    helpLinkBannerModerationRules: promotionConfig.banner_link_help_banner_moderation_rules,
    infoOpen,
    setInfoOpen,
    canvasProps
  }
}

export default useBannerSetup
