import {Box} from '@eda-restapp/ui'
import cn from 'classnames'
import type {FC} from 'react'

import styles from './BonusesProgressBar.module.css'

type ProgressBarProps = {
  current: number
  max: number
  burnSoon?: boolean
}

export const BonusesProgressBar: FC<ProgressBarProps> = ({current, max, burnSoon}) => {
  return (
    <Box className={styles.root}>
      <Box
        data-testid='bonuses-progress-bar'
        className={cn(styles.progress, {[styles.progressBurning]: burnSoon})}
        style={{width: (current / max) * 100 + '%'}}
      />
    </Box>
  )
}
