import {useI18n} from '@eda-restapp/i18n'
import {Box, Typography, BonusIcon, Button, useIsMobileStrict, Spinner} from '@eda-restapp/ui'
import type {FC} from 'react'

import {formatNumber} from '@restapp/shared/utils'
import type {BonusesCampaign} from '@restapp/shared-api/types/promotion'

import {PlaceCardContainer} from '../../../../../PlaceCard/PlaceCard.container'

import styles from './AvailableBonusesSection.module.css'
import {MAX_CAMPAIGNS_TO_DISPLAY} from './helpers/getBonusesCampaigns'

export type AvailableBonusesSectionProps = {
  bonusesBalance?: string
  bonusesCampaigns?: BonusesCampaign[]
  manyBonusesCampaigns: boolean
  onNavigateToPromotionDrawer: (id: string) => void
  onNavigateToCampaignDrawer: (id: string) => void
  onToggleDrawerTab: () => void
  isBonusesFethed: boolean
}

export const AvailableBonusesSection: FC<AvailableBonusesSectionProps> = ({
  bonusesBalance,
  bonusesCampaigns,
  manyBonusesCampaigns,
  onNavigateToPromotionDrawer,
  onNavigateToCampaignDrawer,
  onToggleDrawerTab,
  isBonusesFethed
}) => {
  const {t, lang} = useI18n()
  const isMobile = useIsMobileStrict()

  return (
    <Box p={isMobile ? 'm' : 'l'} pb='xl' flexDirection='column'>
      <Typography variant='body1' thickness='medium' lineHeight='tight' className={styles.titleMargin}>
        {t('promotion.available-bonuses-section.title', 'Доступные бонусы')}
      </Typography>
      <Typography
        variant={isMobile ? 'caption1' : 'body2'}
        lineHeight={isMobile ? 'loose' : 'normal'}
        className={styles.subtitleMargin}
      >
        {t('promotion.available-bonuses-section.subtitle', '1 бонус = 1 ₽ на продвижение')}
      </Typography>

      <Box gap='s' className={styles.bonuses} alignItems='center'>
        <Typography variant={isMobile ? 'title2' : 'header'} thickness='bold' lineHeight='tight'>
          {formatNumber(Number(bonusesBalance), lang, {useGrouping: true})}
        </Typography>

        <BonusIcon fontSize={isMobile ? 24 : 40} />
      </Box>

      <Box mt={isMobile ? 'm' : 'l'} flexDirection='column' gap={isMobile ? 's' : 'm'}>
        {!isBonusesFethed && (
          <Box flexGrow={1} alignItems='center' justifyContent='center'>
            <Spinner />
          </Box>
        )}

        {isBonusesFethed &&
          bonusesCampaigns?.slice(0, MAX_CAMPAIGNS_TO_DISPLAY).map((campaign, index) => (
            <Box key={campaign.balance_id} flexDirection='column'>
              <PlaceCardContainer
                className={styles.campaignCard}
                burnSoon={campaign.burn_soon}
                currentBalance={campaign.balance}
                placeId={Number(campaign.place_id)}
                totalBalance={campaign.total}
                appliedAt={campaign.applied_at ? new Date(campaign.applied_at) : undefined}
                applyBefore={campaign.apply_before ? new Date(campaign.apply_before) : undefined}
                conditionsLink={campaign.conditions_link}
                onClick={() =>
                  campaign.applied_at
                    ? onNavigateToCampaignDrawer(campaign.place_id)
                    : onNavigateToPromotionDrawer(campaign.place_id)
                }
                upToOrders={campaign.order_cnt}
              />

              {!!manyBonusesCampaigns && index === 2 && <Box className={styles.plug} />}
            </Box>
          ))}
      </Box>

      <Box flexDirection='column' mt={isMobile ? 'm' : 'l'}>
        <Button.Text
          data-testid='all-my-bonuses-button'
          variant='primary'
          fullWidth
          onClick={onToggleDrawerTab}
          disabled={!isBonusesFethed}
        >
          {t('promotion.available-bonuses-section.all-bonuses', 'Все мои бонусы')}
        </Button.Text>
      </Box>
    </Box>
  )
}
