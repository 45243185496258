import {useChannelSubscription, notificationsChannel} from '@eda-restapp/microfrontend'
import type {FC} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router'

import {FINANCE_PATH_PREFIX} from '@restapp/finance/constants'
import {financeSlice} from '@restapp/finance/slice/financeSlice'
import type {FinanceReportType} from '@restapp/finance/types'

export const WatchFinanceNotifications: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const financeCreateDrawerOpen = useSelector(financeSlice.selectors.selectCreateReportDrawerOpen)

  /* При переезде на микрофронт, тут надо будет переписать логику на query-параметры */
  const onSnackbarClick = (reportId: string, report_type: FinanceReportType) => {
    if (financeCreateDrawerOpen) {
      return
    }

    navigate(`${FINANCE_PATH_PREFIX}/${report_type}`)
    dispatch(financeSlice.actions.openInfoDrawer(reportId))
  }

  useChannelSubscription(notificationsChannel, (message) => {
    if (
      (message.event === 'reports.report_is_ready' || message.event === 'reports.report_building_failed') &&
      message.meta.clicked
    ) {
      onSnackbarClick(message.data.report_id, message.data.report_type as FinanceReportType)
    }
  })

  return null
}
