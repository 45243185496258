import type {FC} from 'react'
import React from 'react'
import {useEvent} from 'react-use-event-hook'

import {usePlaces} from '@restapp/core-places'
import {useAppSelector} from '@restapp/store'

import {chatsSlice} from '../../slice/chatsSlice'
import type {IChat} from '../../types'

import {ChatListItem} from './ChatListItem'

type ChatListItemProps = {
  chat: IChat
  onClick(): void
}

export const ChatListItemContainer: FC<ChatListItemProps> = ({chat, onClick}) => {
  const multiChatUnreadCount = useAppSelector((state) => {
    return chatsSlice.selectors.selectMultiChatUnreadCountById(state, chat.chat_id || '')
  })

  const unreadCount = chat.new_messages_counter || multiChatUnreadCount

  const onClickStable = useEvent(onClick)

  const {getPlaceById} = usePlaces()

  const place = getPlaceById(Number(chat.place_id))

  return (
    <ChatListItem
      chatPlaceName={place?.name}
      chatPlaceAddress={place?.address}
      chatOrderNr={chat.order_nr ? `№${chat.order_nr}` : undefined}
      chatAuthorEmail={chat.author_email}
      chatTopic={chat.topic}
      chatCreatedAt={chat.created_at}
      chatStatus={chat.status}
      unreadCount={unreadCount}
      onClick={onClickStable}
    />
  )
}
