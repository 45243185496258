import {eventLogger, metrika} from '@eda-restapp/logger'
import {notificationsChannel} from '@eda-restapp/microfrontend'
import {useSupportChat} from '@restapp/main-chats/hooks/useSupportChat'
import {chatsSlice} from '@restapp/main-chats/slice/chatsSlice'
import {memo, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'

export const ProcessClickedNotifications = memo(() => {
  const navigate = useNavigate()
  const {openChat} = useSupportChat()
  const dispatch = useDispatch()

  useEffect(
    () =>
      notificationsChannel.subscribe((notification) => {
        if (!notification.meta.clicked) {
          return
        }

        eventLogger({
          name: 'notification_clicked',
          value: `notifications:${notification.meta.source}:${notification.event}:clicked`,
          additional: {notification}
        })

        metrika({
          target: 'notification_clicked',
          params: {
            event: notification.event,
            source: notification.meta.source
          }
        })

        switch (notification.event) {
          case 'order_changed_courier':
          case 'order_changed_items':
          case 'order_changed_status':
          case 'order_updated_courier':
          case 'order_new': {
            navigate(`/active/${notification.data.orderNr}`)
            break
          }

          case 'direct_low_balance': {
            navigate('/promotion/places/?modal=payment')
            break
          }

          case 'place_leader_changed': {
            navigate(`/place-leaders/${notification.data.place_id}`)
            break
          }

          case 'place_blocked': {
            if (notification.data.type === 'single' && notification.data.place_id) {
              navigate(`/places/${notification.data.place_id}/info?blockInfoPlaceId=${notification.data.place_id}`)
            } else {
              navigate('/places')
            }
            break
          }

          case 'new_yamb_csat_message':
          case 'new_yamb_message': {
            if (notification.data.chat_id) {
              dispatch(
                chatsSlice.actions.openMultiChatDrawer({
                  chatId: notification.data.chat_id,
                  ticketId: notification.data.ticket_id
                })
              )
            }
            break
          }

          case 'new_message': {
            openChat(notification.data.chat_id)
            break
          }

          default:
            break
        }
      }),
    [dispatch, navigate, openChat]
  )

  return null
})
