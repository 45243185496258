import {useExp3} from '@eda-restapp/configs'
import {metrika} from '@eda-restapp/logger'
import {lazy, useCallback, useEffect, useState} from 'react'

import {createPolling} from '@restapp/core-legacy/api/polling'
import type {WithPortalProps} from '@restapp/core-legacy/hocs/withPortals'
import {withPortals} from '@restapp/core-legacy/hocs/withPortals'
import {usePlaces} from '@restapp/core-places'
import {Suspense} from '@restapp/shared-boundary'

import NotificationModal from '../components/Notifications/NotificationModal/NotificationModal'
import {useNews} from '../hooks/useNews'
import {usePool} from '../hooks/usePool'
import {isNews, isNotification} from '../types'

const NewsModal = lazy(() =>
  import(/* webpackChunkName: "@restapp/shared/components" */ '@restapp/shared/components').then((module) => {
    return {default: module.NewsModal}
  })
)

const DEFAULT_POLLING_LIMIT = 100

// TODO: https://st.yandex-team.ru/EDARESTAPP-9218
function WatchCommunications({openPortal}: WithPortalProps) {
  const {selectedPlaceId, getPlaceById} = usePlaces()

  const pool = usePool()
  const news = useNews()

  const [openedModal, setOpenedModal] = useState<'news' | 'survey' | 'notification' | null>(null)
  const closeModal = () => setOpenedModal(null)

  const restappCommunications = useExp3('restapp_communications')

  const isEnabled = restappCommunications?.enabled
  const pollingLimit = restappCommunications?.pollingLimit ?? DEFAULT_POLLING_LIMIT

  const isValidPlaceId = selectedPlaceId && getPlaceById(selectedPlaceId)

  const setupPolling = useCallback(() => {
    pool.setPollingLimit(pollingLimit)

    return createPolling(() => void pool.poll(selectedPlaceId), pool.pollingInterval, {
      fireImmediately: false
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlaceId, pool.pollingInterval, pollingLimit])

  useEffect(() => {
    // Изменился ресторан
    if (!isValidPlaceId) {
      return
    }

    pool.poll(selectedPlaceId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlaceId, isValidPlaceId])

  useEffect(() => {
    // Изменились параметры поллинга
    if (!isEnabled || !isValidPlaceId) {
      return
    }

    const polling = setupPolling()
    polling.start()

    return polling.stop
  }, [isEnabled, setupPolling, isValidPlaceId])

  useEffect(() => {
    const fullscreen = pool.forFullScreen
    if (!fullscreen) {
      return
    }

    metrika({
      target: 'show_notification_fullscreen',
      params: {id: fullscreen.id, page: location.pathname}
    })

    if (isNews(fullscreen)) {
      setOpenedModal('news')
    } else if (isNotification(fullscreen)) {
      openPortal(<NotificationModal notification={fullscreen} />)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pool.forFullScreen])

  if (pool.forFullScreen) {
    const fullscreen = pool.forFullScreen

    return (
      <>
        {isNews(fullscreen) && (
          <Suspense>
            <NewsModal
              id={fullscreen.id}
              open={openedModal === 'news'}
              title={fullscreen.preview.title}
              isStory={fullscreen.content.content_type === 'story'}
              pages={fullscreen.content.pages}
              onClose={() => {
                if (fullscreen.status === 'published') {
                  news.markRead(fullscreen.id)
                }
                closeModal()
              }}
            />
          </Suspense>
        )}
      </>
    )
  }

  return null
}

export default withPortals(WatchCommunications)
