/* eslint-disable compat/compat */
import {memo, useEffect} from 'react'
import type {XivaNotification} from '../types'
import {notificationsChannel} from '@eda-restapp/microfrontend'
import {broadcastXivaNotification} from '../broadcastXivaNotification'
import {useQuery} from '@tanstack/react-query'
import {eventLogger} from '@eda-restapp/logger'

type WebPushEvent = {
  type: 'WEBPUSH_CLICKED' | 'WEBPUSH_DISPLAYED'
  data: XivaNotification
}

const isWebPushEvent = (
  event: MessageEvent<Pick<Partial<WebPushEvent>, 'type'>>
): event is MessageEvent<WebPushEvent> =>
  event.data?.type === 'WEBPUSH_CLICKED' || event.data?.type === 'WEBPUSH_DISPLAYED'

export const WebPushListener = memo(() => {
  const leadershipQuery = useQuery({
    queryKey: ['webpush-notifications-leadership'],
    queryFn: () => notificationsChannel.leaderElector.awaitLeadership(),
    staleTime: Infinity
  })

  const hasLeadership = leadershipQuery.isFetched

  useEffect(() => {
    if (!hasLeadership) {
      return
    }

    const handleSwMessage = (event: MessageEvent<any>) => {
      if (isWebPushEvent(event)) {
        const xivaNotification = event.data.data

        eventLogger({
          name: 'webpush',
          value: `webpush:${xivaNotification.eventData?.event}:${event.data.type}`,
          additional: {xivaNotification}
        })

        broadcastXivaNotification(notificationsChannel, xivaNotification, {
          clicked: event.data.type === 'WEBPUSH_CLICKED',
          source: 'web-push'
        })
      }
    }

    navigator.serviceWorker.addEventListener('message', handleSwMessage)

    navigator.serviceWorker.ready.then((registration) =>
      registration.active?.postMessage({type: 'WEBPUSH_LEADER_READY'})
    )

    return () => navigator.serviceWorker.removeEventListener('message', handleSwMessage)
  }, [hasLeadership])

  return null
})
