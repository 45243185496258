import {CrossLIcon} from '@eda-restapp/ui'
import cn from 'classnames'
import React from 'react'
import {compose} from 'recompose'
import {GlobalStyles} from 'tss-react'
import {withStyles} from 'tss-react/mui'

import {S} from '../../style'
import type {WithDisposerProps} from '@restapp/core-legacy/hocs/disposer'
import withDisposer from '@restapp/core-legacy/hocs/disposer'
import type {PortalComponentProps} from '@restapp/core-legacy/hocs/withPortals'

const ANIMATION_TIME = 200
const ANIMATION_TIME_DESKTOP = 300

export type LayoutProps = {
  children: React.ReactNode
  closingByEscAvailable?: boolean
  closingByClickOutside?: boolean
  resizable?: boolean
  onClose?: () => void
} & {
  className?: string
  classes?: Partial<Record<'root' | 'wrap' | 'content' | 'visible' | 'backdrop' | 'close' | 'resizable', string>>
} & Partial<PortalComponentProps<any>>

type InnerProps = LayoutProps &
  WithDisposerProps & {
    classes: Record<'root' | 'wrap' | 'content' | 'visible' | 'backdrop' | 'close' | 'resizable', string>
  }

class LayoutModal extends React.Component<InnerProps> {
  private animationTimer?: number

  componentDidMount() {
    this.props.onMount && this.props.setTimeout(this.props.onMount, 15)
    document.addEventListener('keydown', this.handleKeyDown)
    this.props.closingByClickOutside && document.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
    this.props.closingByClickOutside && document.removeEventListener('click', this.handleClickOutside)
  }

  UNSAFE_componentWillReceiveProps(nextProps: InnerProps) {
    if (this.props.visible) {
      if (!nextProps.visible) {
        nextProps.onHide && this.enableAnimationTimer(nextProps.onHide)
      }
    } else {
      if (nextProps.visible) {
        nextProps.onShow && this.enableAnimationTimer(nextProps.onShow)
      }
    }
  }

  disableAnimationTimer() {
    this.props.clearTimeout(this.animationTimer)
  }

  enableAnimationTimer(callback: () => void) {
    this.disableAnimationTimer()
    this.animationTimer = this.props.setTimeout(callback, ANIMATION_TIME + 15)
  }

  handleClose(): void {
    const {onClose, close} = this.props

    if (onClose) {
      onClose()
    }

    close && close(false)
  }

  handleKeyDown = (e: KeyboardEvent): void => {
    if (e.key === 'Escape' && this.props.closingByEscAvailable) {
      this.handleClose()
    }
  }

  handleClickOutside = (e: MouseEvent) => {
    const contentDiv = document.getElementsByClassName(this.props.classes.content)[0]
    if (e.composedPath().includes(contentDiv)) {
      return
    }

    this.handleClose()
  }

  render() {
    const {classes: c, children, visible, close, resizable = false} = this.props

    return (
      <div
        className={cn(c.root, {
          [c.visible]: visible,
          [c.resizable]: resizable
        })}
        data-testid={'ui__modal' /*UI | Modal root*/}
      >
        <div className={c.wrap}>
          <div className={c.content}>
            {!!close && (
              <CrossLIcon
                className={c.close}
                onClick={() => this.handleClose()}
                data-testid={'ui-modal-close' /*UI | Modal close icon*/}
              />
            )}
            {children}
          </div>
        </div>
        <div className={c.backdrop} />
        <GlobalStyles
          styles={{
            [S.mobileQuery]: {
              'html[data-hide-scroll] #root': {
                overflow: 'hidden'
              }
            }
          }}
        />
      </div>
    )
  }
}

export default compose<InnerProps, LayoutProps>(withDisposer)(
  withStyles(
    LayoutModal,
    (_theme, _props, classes) =>
      ({
        root: {
          position: 'fixed',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0
        },

        wrap: {
          zIndex: 2,
          position: 'relative',
          maxWidth: 700,
          display: 'flex',
          textAlign: 'initial',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 30,
          minWidth: 300,
          margin: '0 auto',
          transition: `all ${ANIMATION_TIME_DESKTOP}ms`,
          opacity: 0,
          transform: 'scale(1) translateY(50px)',
          height: '100%',
          flexDirection: 'column',
          overflowY: 'hidden',

          [S.mobileQuery]: {
            height: '100%',
            maxHeight: '100%',
            maxWidth: '100%',
            width: '100%',
            position: 'absolute',
            transition: `all ${ANIMATION_TIME_DESKTOP}ms`,
            transform: 'scale(0.9) translateY(30px)',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0
          }
        },

        content: {
          background: '#ffffff',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0px 2px 40px rgba(0, 0, 0, 0.15)',
          borderRadius: 24,
          width: '100%',
          maxHeight: 'calc(100% - 64px)',
          overflow: 'hidden',
          zIndex: 1,

          [S.mobileQuery]: {
            // @ts-ignore - https://github.com/mui-org/material-ui/issues/12277#issuecomment-417417343
            height: '100%',
            borderRadius: 0,
            maxHeight: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0
          }
        },

        visible: {
          [`& .${classes.wrap}`]: {
            opacity: 1,
            transform: 'scale(1)  translateY(0)'
          },
          [`& .${classes.backdrop}`]: {
            opacity: 1
          }
        },

        backdrop: {
          zIndex: 1,
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          opacity: 0,
          background: 'rgba(0, 0, 0, 0.5)',
          transition: `opacity ${ANIMATION_TIME_DESKTOP}ms`,

          [S.mobileQuery]: {
            display: 'none'
          }
        },

        close: {
          position: 'absolute',
          top: 30,
          right: 24,
          cursor: 'pointer',
          zIndex: 2,

          [S.mobileQuery]: {
            top: 18,
            right: 18
          }
        },

        resizable: {
          [`& .${classes.wrap}`]: {
            maxWidth: 'none'
          },
          [`& .${classes.content}`]: {
            width: 'auto'
          }
        }
      }) as const
  )
)
