import type {UseQueryResult} from '@tanstack/react-query'
import {useQuery} from '@tanstack/react-query'

import {incrementalDelay} from '@restapp/shared/utils/IncrementalDelay/incrementalDelay'
import type {IApiError, VendorApi as Api} from '@restapp/shared-api'

import createReactQueryOptions from './createReactQueryOptions'
import {useApiDeps} from './hooks/useApiDeps'
import {useSocketEvents} from './hooks/useSocketEvents'
import useSocketStatus from './hooks/useSocketStatus'
import type {ApiQueryConfig, ApiQueryKey, ApiResponse} from './types'

export default function useApiQuery<
  TUrl extends keyof Api,
  TMethod extends keyof Api[TUrl],
  TSelectedData = ApiResponse<Api[TUrl][TMethod]>
>(
  config: ApiQueryConfig<TUrl, TMethod, TSelectedData>
): UseQueryResult<TSelectedData, IApiError> & {
  queryKey: ApiQueryKey<TUrl, TMethod>
} {
  const apiDeps = useApiDeps()
  const isSocketConnected = useSocketStatus()

  const {
    url,
    method,
    params,
    body,
    headers,
    refetchOnSocketEvent,
    refetchInterval,
    refetchIntervalWithSocket = refetchInterval,
    ...options
  } = config

  const reactQueryOptions = createReactQueryOptions<TUrl, TMethod, TSelectedData>(
    {url, method, params, body, headers},
    apiDeps
  )

  const result = useQuery<ApiResponse<Api[TUrl][TMethod]>, IApiError, TSelectedData, ApiQueryKey<TUrl, TMethod>>({
    retryDelay: incrementalDelay,
    ...reactQueryOptions,
    refetchInterval: isSocketConnected ? refetchIntervalWithSocket : refetchInterval,
    ...options
  })

  useSocketEvents({
    eventFilter: refetchOnSocketEvent,
    eventHandler: () => (result.isFetching ? undefined : result.refetch)
  })

  return {
    ...result,
    queryKey: reactQueryOptions.queryKey
  }
}
