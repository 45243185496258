import {Drawer} from '@eda-restapp/ui'
import type {FC} from 'react'

import type {BonusesCampaign} from '@restapp/shared-api/types/promotion'

import {BonusesListTabContainer} from './components/BonusesListTab/BonusesListTab.container'
import {PromoTab} from './components/PromoTab/PromoTab'
import type {Tab} from './PromoDrawer.container'

type PromoDrawerProps = {
  isOpen: boolean
  onClose: () => void
  tab: Tab
  onToggleDrawerTab: () => void
  newBonusesCampaigns?: BonusesCampaign[]
  acceptedBonusesCampaigns?: BonusesCampaign[]
}

export const PromoDrawer: FC<PromoDrawerProps> = ({isOpen, onClose, tab, onToggleDrawerTab}) => {
  const isAvailableBonuses = tab === 'availableBonuses'

  return (
    <Drawer.Panel opened={isOpen} onClose={onClose}>
      {isAvailableBonuses ? (
        <PromoTab onToggleDrawerTab={onToggleDrawerTab} onClose={onClose} />
      ) : (
        <BonusesListTabContainer onToggleDrawerTab={onToggleDrawerTab} onClose={onClose} />
      )}
    </Drawer.Panel>
  )
}
