import type {FC, ComponentType} from 'react'
import {memo} from 'react'
import {useExp3} from '@eda-restapp/configs'
import {WebPushListener} from './WebPushListener'
import {WebPushSubscription} from './WebPushSubscription'
import {useQuery} from '@tanstack/react-query'

const isWebPushesSupported =
  'ServiceWorkerRegistration' in window &&
  'showNotification' in ServiceWorkerRegistration.prototype &&
  'PushManager' in window

export const WebPush: FC<{Fallback: ComponentType}> = memo(({Fallback}) => {
  const webPushConfig = useExp3('restapp_webpush')
  const webpushPermissionQuery = useQuery({
    queryKey: ['webpush-permission'],
    enabled: isWebPushesSupported,
    staleTime: Infinity,

    queryFn: async () => {
      // eslint-disable-next-line compat/compat
      const registration = await navigator.serviceWorker.ready

      const permissionState = await registration.pushManager.permissionState({userVisibleOnly: true})

      return permissionState
    }
  })

  const isPermissionDenied = webpushPermissionQuery.data === 'denied'

  if (!isWebPushesSupported || isPermissionDenied || !webPushConfig.enabled) {
    return <Fallback />
  }

  const isWaitingForPermissionDecision = webpushPermissionQuery.data === 'prompt'

  return (
    <>
      {/* Фоллбэчимся, пока юзер решает выдавать ли пермишен на пуши */}
      {isWaitingForPermissionDecision && <Fallback />}
      <WebPushSubscription
        vapidKeyUrl={webPushConfig.vapidKeyUrl}
        onSubscriptionError={webpushPermissionQuery.refetch}
        onSubscriptionSuccess={webpushPermissionQuery.refetch}
      />
      <WebPushListener />
    </>
  )
})
