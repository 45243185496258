import React, {type FC} from 'react'

import type {ShortRestaurant} from '@restapp/shared-api/types/places'

import {Item} from '../Item'
import {DeliveryIcon} from '@eda-restapp/ui'
import {useDeliveryName} from '@eda-restapp/i18n'

type RestaurantListItemProps = {
  place: Pick<ShortRestaurant, 'name' | 'address' | 'services' | 'type'>
  disabled?: boolean
  className?: string
  disabledIcon?: boolean
  children?: React.ReactNode
  subtitle?: React.ReactNode
}

export const RestaurantListItem: FC<RestaurantListItemProps> = ({
  place,
  children,
  disabled,
  className,
  disabledIcon = false,
  subtitle
}) => {
  const getDeliveryName = useDeliveryName()

  const deliveryName = getDeliveryName({placeServices: place.services, placeType: place.type})

  const icon = <DeliveryIcon deliverySlug={deliveryName.slug} tooltip={deliveryName.name} disabled={disabledIcon} />

  return (
    <Item icon={icon} disabled={disabled} title={place.name} subTitle={place.address} className={className}>
      {children}
      {subtitle}
    </Item>
  )
}
