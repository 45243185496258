import {Box, Typography, useMediaQuery} from '@eda-restapp/ui'
import cn from 'classnames'
import type {FC} from 'react'

import {PROMO_IMAGE_MOBILE_SIZE} from '../WhiteCard/WhiteCard'

import styles from './TransparentCard.module.css'

type TransparentCardProps = {
  className: string
  title: string
  badge: string
}

export const TransparentCard: FC<TransparentCardProps> = ({className, title, badge}) => {
  const isCustomMobile = useMediaQuery(PROMO_IMAGE_MOBILE_SIZE)

  return (
    <Box className={cn(styles.root, className)} p='m' flexDirection='column'>
      <Typography
        variant={isCustomMobile ? 'caption2' : 'body2'}
        thickness='bold'
        lineHeight={isCustomMobile ? 'normal' : 'tight'}
        color='invert'
      >
        {title}
      </Typography>

      <Box className={styles.badge} mt={isCustomMobile ? 'm' : 'l'}>
        <Typography variant='caption1' color='invert' thickness='medium' className={styles.badgeText}>
          {badge}
        </Typography>
      </Box>
    </Box>
  )
}
