import {useI18n} from '@eda-restapp/i18n'
import {ArrowLeftIcon, Box, Typography, BonusIcon, useIsMobileStrict, ChevronLeftLIcon} from '@eda-restapp/ui'
import type {FC} from 'react'

import {formatNumber} from '@restapp/shared/utils'

import styles from './HeadSection.module.css'

type HeadSectionProps = {
  onToggleDrawerTab: () => void
  total?: string
}

export const HeadSection: FC<HeadSectionProps> = ({onToggleDrawerTab, total}) => {
  const {t, lang} = useI18n()
  const isMobile = useIsMobileStrict()

  return (
    <Box
      flexDirection='column'
      className={styles.root}
      p={isMobile ? undefined : 'l'}
      pt={isMobile ? 's' : 'm'}
      pb={isMobile ? 'm' : undefined}
    >
      <Box className={styles.headTitle} gap='m'>
        <Box flexDirection='column' className={styles.arrowWrapper} onClick={onToggleDrawerTab}>
          {isMobile ? (
            <Box ml='m'>
              <ChevronLeftLIcon color='white' fontSize={20} />
            </Box>
          ) : (
            <ArrowLeftIcon color='white' fontSize={24} />
          )}
        </Box>
        <Typography
          variant={isMobile ? 'body2' : 'title4'}
          thickness='medium'
          lineHeight={isMobile ? 'tight' : 'normal'}
          color='invert'
        >
          {t('promotion.bonuses-list-tab.my-bonuses', 'Мои бонусы')}
        </Typography>
      </Box>
      <Typography
        variant='body2'
        color='invert'
        lineHeight='normal'
        className={styles.subtitle}
        pl={isMobile ? 'm' : undefined}
      >
        {t('promotion.bonuses-list-tab.bonuses-for-promotion', '1 бонус = 1 ₽ на продвижение')}
      </Typography>
      <Box gap='s' className={styles.bonuses} pl={isMobile ? 'm' : undefined} alignItems='center'>
        <Typography variant={isMobile ? 'title2' : 'header'} thickness='bold' lineHeight='tight' color='invert'>
          {formatNumber(Number(total), lang, {useGrouping: true})}
        </Typography>
        <BonusIcon fontSize={isMobile ? 24 : 40} color='white' />
      </Box>
    </Box>
  )
}
