import {Box, Clamp, Typography} from '@eda-restapp/ui'
import moment from 'moment'
import React, {memo, type FC} from 'react'

import {MultiChatStatusBadge} from '@restapp/main-chats/messenger/components/MultiChatStatusBadge/MultiChatStatusBadge'

import {UnreadBadge} from '../UnreadBadge'

import styles from './ChatListItem.module.css'

type ChatListItemProps = {
  chatStatus?: 'open' | 'solved' | 'pending'
  chatAuthorEmail: string
  chatCreatedAt?: string
  chatTopic: string
  chatOrderNr?: string
  chatPlaceName?: string
  chatPlaceAddress?: string
  unreadCount?: number
  onClick(): void
}

const ChatListItemRaw: FC<ChatListItemProps> = ({
  chatCreatedAt,
  chatAuthorEmail,
  chatStatus,
  chatTopic,
  onClick,
  unreadCount,
  chatOrderNr,
  chatPlaceName,
  chatPlaceAddress
}) => {
  return (
    <div className={styles.root} onClick={onClick}>
      <Box alignItems='center' gap='s'>
        <Box alignItems='center' gap='xs'>
          <Typography variant='body2' thickness='bold'>
            <Clamp className={styles.clamp} title={chatTopic}>
              {chatTopic}
            </Clamp>
          </Typography>

          {!!unreadCount && <UnreadBadge count={unreadCount} />}
        </Box>

        <MultiChatStatusBadge status={chatStatus} />

        {!!chatCreatedAt && (
          <Typography variant='caption1' color='minor' className={styles.date}>
            {moment(chatCreatedAt).format('DD.MM')}
          </Typography>
        )}
      </Box>

      <div className={styles.info}>
        {!!chatOrderNr && (
          <Typography variant='caption1'>
            <Clamp className={styles.clamp} title={chatTopic}>
              {chatOrderNr}
            </Clamp>
          </Typography>
        )}

        {!!chatAuthorEmail && (
          <Typography variant='caption1'>
            <Clamp className={styles.clamp} title={chatTopic}>
              {chatAuthorEmail}
            </Clamp>
          </Typography>
        )}

        {(!!chatPlaceName || !!chatPlaceAddress) && (
          <div className={styles.place}>
            {!!chatPlaceName && (
              <Typography variant='caption1'>
                <Clamp className={styles.clamp} title={chatTopic}>
                  {chatPlaceName}
                </Clamp>
              </Typography>
            )}

            {!!chatPlaceAddress && (
              <Typography color='minor' variant='caption1'>
                <Clamp className={styles.clamp} title={chatTopic}>
                  {chatPlaceAddress}
                </Clamp>
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export const ChatListItem = memo(ChatListItemRaw)
