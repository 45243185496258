import {metrika} from '@eda-restapp/logger'
import {useEffect, useMemo, useState, type FC} from 'react'
import {useNavigate} from 'react-router'

import {useBonusesBalance} from '../../../../hooks/useBonusesBalance'
import {usePromoDrawerContext} from '../../hooks/usePromoDrawerContext'

import {BonusesListTab} from './BonusesListTab'
import {useLoadBonusesCampaigns} from './hooks/useLoadBonusesCampaigns'

type BonusesListTabContainerProps = {
  onToggleDrawerTab: () => void
  onClose: () => void
}

export type BonusesTab = 'new' | 'accepted'

export const BonusesListTabContainer: FC<BonusesListTabContainerProps> = ({onToggleDrawerTab, onClose}) => {
  const bonusesBalance = useBonusesBalance()
  const navigate = useNavigate()
  const [tab, setTab] = useState<BonusesTab>('new')

  const promoDrawerContext = usePromoDrawerContext()

  const promotionCampaigns = promoDrawerContext.campaigns

  const notActivatedCampaigns = useMemo(() => {
    if (!promotionCampaigns) {
      return undefined
    }

    return promotionCampaigns
      .filter((campaign) => !campaign.activated_by_user && campaign.has_access_vendor)
      .map((campaign) => campaign.place_id)
  }, [promotionCampaigns])

  const newBonusesCampaigns = useLoadBonusesCampaigns({
    campaignsStatus: 'new',
    placesIds: notActivatedCampaigns
  })
  const acceptedBonusesCampaigns = useLoadBonusesCampaigns({campaignsStatus: 'accepted'})

  const isEmptyNewBonusesCampaigns = newBonusesCampaigns.campaigns?.length === 0

  useEffect(() => {
    if (isEmptyNewBonusesCampaigns) {
      setTab('accepted')
    }
  }, [isEmptyNewBonusesCampaigns])

  const handleToggleBonusesTab = () => {
    setTab(tab === 'new' ? 'accepted' : 'new')
  }

  const isNewBonusesListTab = tab === 'new'

  const handleNavigateToPromotion = () => {
    metrika({target: 'bonuses_promo_drawer_click_to_promotion'})

    navigate('/promotion')
    onToggleDrawerTab()
  }

  const handleNavigateToPromotionDrawer = () => {
    if (newBonusesCampaigns.campaigns) {
      const placeIds = newBonusesCampaigns.campaigns.map((campaign) => campaign.place_id)
      metrika({target: 'bonuses_promo_drawer_click_use_all', params: {placeIds}})

      navigate(`/promotion/places?startByBonusesIds=${placeIds.join(',')}`)
      onToggleDrawerTab()
    }
  }

  return (
    <BonusesListTab
      tab={tab}
      total={bonusesBalance.total}
      onToggleDrawerTab={onToggleDrawerTab}
      onClose={onClose}
      isNewBonusesListTab={isNewBonusesListTab}
      onToggleBonusesTab={handleToggleBonusesTab}
      onNavigateToPromotion={handleNavigateToPromotion}
      onNavigateToPromotionDrawer={handleNavigateToPromotionDrawer}
      newBonusesCampaigns={newBonusesCampaigns.campaigns}
      acceptedBonusesCampaigns={acceptedBonusesCampaigns.campaigns}
      isBonusesFetched={!!newBonusesCampaigns.campaigns && !!acceptedBonusesCampaigns.campaigns}
      isBonusesLoading={newBonusesCampaigns.isLoading || acceptedBonusesCampaigns.isLoading}
      canLoadMore={isNewBonusesListTab ? newBonusesCampaigns.canLoadMore : acceptedBonusesCampaigns.canLoadMore}
      onLoadMore={isNewBonusesListTab ? newBonusesCampaigns.loadMore : acceptedBonusesCampaigns.loadMore}
    />
  )
}
