import {makeStyles} from 'tss-react/mui'

const useSidebarBadgeStyles = makeStyles()({
  BETA: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    width: 38,
    height: 20,
    background: '#00CA50',
    borderRadius: 4,
    color: '#FFFFFF',
    fontSize: 12,

    '&:after': {
      content: '"Beta"'
    },

    // См. Sidebar.tsx
    '[data-isminidrawer="true"] &': {
      display: 'none'
    }
  },
  NEW: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 38,
    height: 20,
    marginLeft: 'auto',
    borderRadius: '4px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#fff',
    background: '#f5523a',

    '&:after': {
      content: '"New"'
    },

    '[data-isminidrawer="true"] &': {
      display: 'none'
    }
  },
  NOTIFY_RED: {
    marginRight: 'auto',
    background: '#F5523A',
    boxShadow: '0px 1px 6px rgba(245, 82, 58, 0.4)',
    borderRadius: 12,
    display: 'inline-block',
    top: '-5px',
    position: 'relative',
    right: '-6px',
    width: 12,
    height: 12,
    flexShrink: 0,

    '[data-isminidrawer="true"] &': {
      position: 'absolute',
      right: 6,
      top: 6
    }
  },
  NOTIFY_COUNT: {
    maxWidth: 47,
    height: 20,
    fontSize: 13,
    lineHeight: '14px',
    borderRadius: '100px',
    marginLeft: 'auto',
    background: '#FA6A3C',
    padding: '2px 7px',
    color: '#FFFFFF',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',

    '[data-isminidrawer="true"] &': {
      position: 'absolute',
      top: 0,
      right: -2
    }
  }
})

export default useSidebarBadgeStyles
